
import {defineComponent} from "vue";
import {bus} from "@/modules/eventBus";
import Button from "@/components/UI/Button.vue";

export default defineComponent({
  components: {Button},
  data() {
    return {
      currentViewType: 'resourceTimelineDay',
      activeStart: ''
    }
  },
  mounted() {
    bus.on('availability:update:activeStart', (activeStart) => this.setActiveStart(activeStart as string))
  },
  methods: {
    previous() {
      bus.emit('availability:actions:previous')
    },
    next() {
      bus.emit('availability:actions:next')
    },
    today() {
      bus.emit('availability:actions:today')
    },
    changeView(viewType: string) {
      bus.emit('availability:actions:changeView', viewType)
      this.currentViewType = viewType
    },
    setActiveStart(activeStart: string) {
      this.activeStart = activeStart
    },
  },
})
