
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import {Call} from "@/models/scheduling/Call";
import {Person} from "@/models/people/Person";
import {CallType} from "@/models/scheduling/CallType";
import InputGroup from "@/components/UI/InputGroup.vue";
import Selector2 from "@/components/inputs/SSelect2.vue";
import {bus} from "@/modules/eventBus";
import Multiselect from "@vueform/multiselect";
import {CallService} from "@/services/CallService";
import {useRoute} from "vue-router";
import Swal from "sweetalert2";
import {PeopleService} from "@/services/PeopleService";
import {CallTypeService} from "@/services/CallTypeService";
import {VenueService} from "@/services/VenueService";
import Button from "@/components/UI/Button.vue";
import {Venue} from "@/models/venues/Venue";
import moment from "moment";
import {CallCalendarEvent} from "@/models/scheduling/CallCalendarEvent";
import {GroupService} from "@/services/GroupService";
import {GroupViewModel} from "@/models/groups/GroupViewModel";
import useVuelidate from "@vuelidate/core";
import {required, requiredIf} from "@vuelidate/validators";
import {MembershipStatus} from "@/models/enums/MembershipStatus";
import {UserConflictViewModel} from "@/models/UserConflictViewModel";
import {AxiosError} from "axios";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import ResourceViewer from "@/components/Calendar/ResourceViewer.vue";
import {AddMultipleCallsCommand, CallDateSet} from "@/models/scheduling/CallCommand";
import {ValidateCallConflictsCommand} from "@/models/scheduling/ValidateCallConflictsCommand";
import {useToast} from "vue-toastification";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
    setup(_, {emit}) {
        const modal = ref<InstanceType<typeof ModalBase>>();
        let call = ref<Call>({description: ''} as Call);
        let dateSet = ref<Array<CallDateSet>>([] as Array<CallDateSet>);
        let people = ref<Array<Person>>([] as Array<Person>);
        let currentDateSet = ref<CallDateSet>({} as CallDateSet);
        let callTypes = ref<Array<CallType>>([] as Array<CallType>);
        let venues = ref<Array<Venue>>([] as Array<Venue>);
        let groups = ref<Array<GroupViewModel>>([] as Array<GroupViewModel>);
        let mode = ref<string>('add');
        let tab = ref<string>('general');
        let displayCollisions = ref(false)
        let loadingStates = ref<Object>({
            people: true, groups: true, venues: true, callTypes: true
        });
        const route = useRoute();
        const productionId = () => {
            return route.params['productionId'] as string
        }
        const invokeAdd = () => {
            let currentDate = moment.utc().format('YYYY-MM-DD')
            mode.value = 'add'
            tab.value = 'general'
            call.value = {
                callId: '',
                callTypeId: '',
                end: moment(`${currentDate} 9:00 PM`, 'YYYY-MM-DD h:mm A').toDate(),
                start: moment(`${currentDate} 6:00 PM`, 'YYYY-MM-DD h:mm A').toDate(),
                people: [],
                groups: [],
                productionId: productionId(),
                title: '',
                description: '',
                venueSpaceId: ''
            } as Call
            currentDateSet.value = {
                end: moment(`${currentDate} 9:00 PM`, 'YYYY-MM-DD h:mm A').toDate(),
                start: moment(`${currentDate} 6:00 PM`, 'YYYY-MM-DD h:mm A').toDate(),
                venueSpaceId: ''
            }
            dateSet.value = []
            fetch()

            displayCollisions.value = false
            modal.value?.toggleOpen()
        }
        const invokeUpdate = (_call: CallCalendarEvent) => {
            mode.value = 'update'
            tab.value = 'general'
            dateSet.value = []
            CallService.getCall(_call.callId).then(response => {
                call.value = response.data
                fetch()
                displayCollisions.value = false
                modal.value?.toggleOpen()
            })
        }
        const emitAdded = () => {
            emit('added')
            bus.emit('modal:scheduling:added')
            modal.value?.toggleClosed()
        }
        const emitUpdated = () => {
            emit('updated')
            bus.emit('modal:scheduling:updated')
            modal.value?.toggleClosed()
        }
        const toggleClosed = () => {
            modal.value?.toggleClosed()
        }
        const fetch = () => {
            const _productionId = mode.value === 'update' ? call.value?.productionId : productionId()
            PeopleService.fetchProduction(_productionId).then(response => {
                people.value = response.data
                loadingStates.value['people'] = false
            })
            GroupService.fetchProduction(_productionId).then(response => {
                groups.value = response.data
                loadingStates.value['groups'] = false
            })
            CallTypeService.listProduction(_productionId).then(response => {
                callTypes.value = response.data
                loadingStates.value['callTypes'] = false
            })
            VenueService.fetchProduction(_productionId).then(response => {
                venues.value = response.data
                loadingStates.value['venues'] = false
            })
        }
        return {
            currentDateSet,
            dateSet,
            modal,
            invokeAdd,
            invokeUpdate,
            call,
            mode,
            tab,
            emitAdded,
            emitUpdated,
            people,
            callTypes,
            venues,
            groups,
            loadingStates,
            toggleClosed,
            displayCollisions,
            v$: useVuelidate(),
            toastr: useToast()
        }
    },
    components: {Button, Multiselect, Selector2, InputGroup, ModalBase, QuillEditor, ResourceViewer},
    emits: ['added', 'updated'],
    data() {
        return {
            isLoading: false,
            isValidating: false,
            //tab: 'general',
            collisions: [] as Array<UserConflictViewModel>,
            toolbar: [
                [{'header': []}],
                ['bold', 'italic', 'underline'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'align': []}],
            ],
            selectedDateRange: [],
            selectedTimeRange: [],
            selectedDates: [],
        }
    },
    validations() {
        return {
            call: {
                callTypeId: {required},
                start: {
                    requiredIfUpdateMode: requiredIf(this.mode === 'update')
                },
                end: {
                    requiredIfUpdateMode: requiredIf(this.mode === 'update'),
                    //validIfUpdateMode: validConflictDateRange(this.mode)
                },
                title: {required},
                venueSpaceId: {
                    requiredIfUpdateMode: requiredIf(this.mode === 'update')
                },
            },
            dateSet: {minLength: isValidSize()},
            currentDateSet: {
                start: {required},
                end: {required, validConflictDateRange},
                venueSpaceId: {required},
            }
        }
    },
    mounted() {
        bus.on('modal:scheduling:add', () => {
            this.invokeAdd()
        })
        bus.on('modal:scheduling:update', (_call) => {
            this.invokeUpdate(_call as CallCalendarEvent)
        })
    },
    methods: {
        add() {
            CallService.addMultipleCalls({
                callTypeId: this.call.callTypeId,
                description: this.call.description,
                title: this.call.title,
                people: this.call.people,
                groups: this.call.groups,
                productionId: this.call.productionId,
                dates: this.dateSet
            } as AddMultipleCallsCommand).then(() => {
                this.toastr.success('Call Scheduled')
                this.isLoading = false
                this.emitAdded()
                this.v$.$reset()
            }).catch((ex: AxiosError) => {
                Swal.fire(ex.response?.data.title, ex.response?.data.detail)
            }).finally(() => {
                this.isLoading = false
                WebAnalytics.trackFlexible('Scheduled Call', {
                    productionId: this.call.productionId,
                    peopleCount: this.call.people.length,
                    groupCount: this.call.groups.length,
                    datesCount: this.dateSet.length
                })
            })
        },
        update() {
            CallService.updateCall(this.call.callId, {
                callId: this.call.callId,
                callTypeId: this.call.callTypeId,
                description: this.call.description,
                end: moment(this.call.end).utc().toDate(),
                title: this.call.title,
                people: this.call.people,
                groups: this.call.groups,
                productionId: this.call.productionId,
                start: moment(this.call.start).utc().toDate(),
                venueSpaceId: this.call.venueSpaceId
            }).then(() => {
                this.toastr.success('Call Updated')
                this.isLoading = false
                this.emitUpdated()
                this.v$.$reset()
            }).catch((ex: AxiosError) => {
                Swal.fire(ex.response?.data.title, ex.response?.data.detail)
            }).finally(() => {
                this.isLoading = false
                WebAnalytics.trackFlexible('Updated Call', {})
            })
        },
        remove() {
            Swal.fire({
                title: "Are you sure?",
                confirmButtonText: 'Cancel Call',
                showConfirmButton: true,
                showCancelButton: true,
                cancelButtonText: 'Keep Call'
            }).then(response => {
                if (response.isConfirmed) {
                    CallService.removeCall(this.call.callId).then(() => {
                        bus.emit('modal:scheduling:updated')
                        this.toggleClosed()
                    })
                }
            }).catch((ex: AxiosError) => {
                Swal.fire(ex.response?.data.title, ex.response?.data.detail)
            }).finally(() => {
                this.isLoading = false
                WebAnalytics.trackFlexible('Removed Call', {})
            })
        },
        addDateSet() {
            this.v$.currentDateSet.$touch();
            if (!this.v$.currentDateSet.$invalid) {
                const startTime = moment(this.selectedTimeRange[0]);
                const endTime = moment(this.selectedTimeRange[1]);

                for (let i = 0; i < this.selectedDates.length; i++) {
                    const currentStartDate = moment(this.selectedDates[i]).clone().set({
                        hour: startTime.get('hour'),
                        minute: startTime.get('minute'),
                    });

                    const currentEndDate = moment(this.selectedDates[i]).clone().set({
                        hour: endTime.get('hour'),
                        minute: endTime.get('minute'),
                    });

                    this.dateSet.push({
                        start: currentStartDate.toDate(),
                        end: currentEndDate.toDate(),
                        venueSpaceId: this.currentDateSet.venueSpaceId,
                    });
                }
                this.v$.currentDateSet.$reset();
            }
        },
        removeDateSet(index: number) {
            this.dateSet.splice(index, 1)
        },
        /**
         * Commit Call Operation to API
         * This will optionally validate the call before adding or updating it.
         * @param isNew Set to true to add a new call. False will update an existing call.
         * @param skipValidation Set to true to skip validation and just add or update the call.
         */
        commit(isNew: boolean, skipValidation: boolean) {
            this.v$.$reset()
            this.v$.call.$touch()
            if (!this.v$.call.$invalid) {
                this.isLoading = true
                if (skipValidation) {
                    if (isNew) {
                        this.add()
                    } else {
                        this.update()
                    }
                } else {
                    this.validate().then(response => {
                        if (response.data.length == 0) {
                            if (isNew) {
                                this.add()
                            } else {
                                this.update()
                            }
                        } else {
                            this.isLoading = false
                            this.collisions = response.data
                            this.showCollisions()
                        }
                    }).catch((ex: AxiosError) => {
                        this.isLoading = false
                        Swal.fire(ex.response?.data.title, ex.response?.data.detail)
                    })
                }
            }
        },
        showCollisions() {
            this.displayCollisions = true
        },
        hideCollisions() {
            this.displayCollisions = false
        },
        validate() {
            // If we're updating an existing call, we need to manually set our start/end times in array format.
            if (this.mode === 'update') {
                this.dateSet = [{
                    venueSpaceId: '',
                    end: moment(this.call.end).utc().toDate(),
                    start: moment(this.call.start).utc().toDate(),
                }]
            }
            let command = {
                dates: this.dateSet,
                groups: this.call.groups,
                people: this.call.people
            } as ValidateCallConflictsCommand;
            if (this.mode === 'update') {
                command['callId'] = this.call.callId
            }
            return CallService.validateCallConflicts(command)
        },
        getFriendlyDateTime(date: Date) {
            return moment(date).format('MMM Do h:mm a')
        },
        getFriendlyCollisionDate(collision: UserConflictViewModel): string {
            const startDate = moment(collision.start).format('MM/DD/YYYY')
            const endDate = moment(collision.end).format('MM/DD/YYYY')
            const isSameDay = startDate === endDate
            if (isSameDay) {
                return `${moment(collision.start).format('MMMM Do h:mm a')} - ${moment(collision.end).format('h:mm a')}`
            }
            return `${moment(collision.start).format('MMMM Do h:mm a')} - ${moment(collision.end).format('MMMM Do h:mm a')}`
        },
        getFriendlyCombinationDate(start: Date, end: Date): string {
            const startDate = moment(start).format('MM/DD/YYYY')
            const endDate = moment(end).format('MM/DD/YYYY')
            const isSameDay = startDate === endDate
            if (isSameDay) {
                return `${moment(start).format('MMMM Do h:mm a')} - ${moment(end).format('h:mm a')}`
            }
            return `${moment(start).format('MMMM Do h:mm a')} - ${moment(end).format('MMMM Do h:mm a')}`
        },
        getVenueSpaceName(venueSpaceId: string) {
            return this.venueSpaces.filter(x => x.venueSpaceId === venueSpaceId)[0].name
        },
        showManageScheduleModal(tab) {
            this.toggleClosed()
            bus.emit('modals:schedule:settings', tab)
        }
    },
    computed: {
        callTypesOptions(): Array<Object> {
            return this.callTypes.map(callType => {
                return {text: callType.name, id: callType.callTypeId}
            })
        },
        venueSpaces(): Array<any> {
            let spaces = [] as Array<Object>
            for (let i = 0; i < this.venues.length; i++) {
                //let venue = {text: this.venueSpaces[i].name, children: [] as Array<Object>}
                for (let k = 0; k < this.venues[i].spaces.length; k++) {
                    spaces.push({
                        venueSpaceId: this.venues[i].spaces[k].venueSpaceId,
                        name: this.venues[i].spaces[k].name
                    })
                }
            }
            return spaces;
        },
        venueSpaceOptions: function (): Array<Object> {
            let options = [] as Array<Object>
            for (let i = 0; i < this.venues.length; i++) {
                let venue = {text: this.venues[i].name, children: [] as Array<Object>}
                for (let k = 0; k < this.venues[i].spaces.length; k++) {
                    venue.children.push({
                        id: this.venues[i].spaces[k].venueSpaceId, text: this.venues[i].spaces[k].name
                    })
                }
                options.push(venue)
            }
            return options;
        },
        peopleOptions(): Array<Object> {
            return this.people.filter(person => person.status === MembershipStatus.Active).map(person => {
                return {label: person.fullName, value: person.accountId}
            })
        },
        groupsOptions(): Array<Object> {
            return this.groups.map(group => {
                return {label: group.name, value: group.groupId}
            })
        },
        title(): string {
            return this.mode === 'add' ? 'Schedule New Call' : 'Manage Call'
        },
        startDate: {
            get() {
                return moment(this.call.start).format('YYYY-MM-DD')
            },
            set(date: string) {
                let newDate = moment(date).format('YYYY-MM-DD')
                let currentTime = moment(this.call.start).format('HH:mm')
                this.call.start = moment(`${newDate} ${currentTime}`).toDate()
                let currentEndTime = moment(this.call.end).format('HH:mm')
                this.call.end = moment(`${newDate} ${currentEndTime}`).toDate()
            }
        },
        startTime: {
            get() {
                return moment(this.call.start).format('h:mm A')
            },
            set(time: string) {
                let currentDate = moment(this.call.start).format('YYYY-MM-DD')
                let newTime = moment(time, 'h:mm A').format('h:mm A')
                this.call.start = moment(`${currentDate} ${newTime}`, 'YYYY-MM-DD h:mm A').toDate()
            }
        },
        endTime: {
            get() {
                return moment(this.call.end).format('h:mm A')
            },
            set(time: string) {
                let currentDate = moment(this.call.end).format('YYYY-MM-DD')
                let newTime = moment(time, 'h:mm A').format('h:mm A')
                this.call.end = moment(`${currentDate} ${newTime}`, 'YYYY-MM-DD h:mm A').toDate()
            }
        },
        startSetDate: {
            get() {
                return moment(this.currentDateSet.start).format('YYYY-MM-DD')
            },
            set(date: string) {
                let newDate = moment(date).format('YYYY-MM-DD')
                let currentTime = moment(this.currentDateSet.start).format('HH:mm')
                this.currentDateSet.start = moment(`${newDate} ${currentTime}`).toDate()
                let currentEndTime = moment(this.currentDateSet.end).format('HH:mm')
                this.currentDateSet.end = moment(`${newDate} ${currentEndTime}`).toDate()
            }
        },
        startSetTime: {
            get() {
                return moment(this.currentDateSet.start).format('h:mm A')
            },
            set(time: string) {
                let currentDate = moment(this.currentDateSet.start).format('YYYY-MM-DD')
                let newTime = moment(time, 'h:mm A').format('h:mm A')
                this.currentDateSet.start = moment(`${currentDate} ${newTime}`, 'YYYY-MM-DD h:mm A').toDate()
            }
        },
        endSetTime: {
            get() {
                return moment(this.currentDateSet.end).format('h:mm A')
            },
            set(time: string) {
                let currentDate = moment(this.currentDateSet.end).format('YYYY-MM-DD')
                let newTime = moment(time, 'h:mm A').format('h:mm A')
                this.currentDateSet.end = moment(`${currentDate} ${newTime}`, 'YYYY-MM-DD h:mm A').toDate()
            }
        },
        sortedDateSet(): Array<CallDateSet> {
            return [...this.dateSet].sort((a, b) => moment(a.start).diff(moment(b.start)));
        }
    }
})
const validConflictDateRange = (mode) => (end, vm) => {
    // console.log('validConflictDateRange', moment(vm.call.start).isBefore(moment(end)))
    return mode === 'add' || moment(vm.call.start).isBefore(moment(end));
};

const isValidSize = () => (dateSet) => {
    return dateSet.length > 0;
}
