import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 m-8 fw-bolder flex-nowrap" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = { class: "nav-item" }
const _hoisted_4 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", _hoisted_2, [
      _createElementVNode("a", {
        class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('user-communications')}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigate('user-communications')))
      }, "Communications", 2)
    ]),
    _createElementVNode("li", _hoisted_3, [
      _createElementVNode("a", {
        class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('user-schedule')}]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigate('user-schedule')))
      }, "Calendar", 2)
    ]),
    _createElementVNode("li", _hoisted_4, [
      _createElementVNode("a", {
        class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('user-memberships')}]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navigate('user-memberships')))
      }, "Productions", 2)
    ])
  ]))
}