
import {defineComponent} from "vue";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";

export default defineComponent({
  setup(){
    const userStore = useUser();
    const applicationStore = useApplication()
    return {applicationStore, userStore}
  },
  methods: {
    changeTab(tab: string) {
      this.applicationStore.changePeopleTab(tab)
    },
  }
})
