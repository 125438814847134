import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex mb-2" }
const _hoisted_2 = {
  key: 1,
  class: "text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_Selector2 = _resolveComponent("Selector2")!
  const _component_ColorSelector = _resolveComponent("ColorSelector")!
  const _component_ModalBase = _resolveComponent("ModalBase")!

  return (_openBlock(), _createBlock(_component_ModalBase, {
    title: "Manage Call Type",
    ref: "modal",
    "has-footer": true
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
        (_ctx.mode !== 'add')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "btn-sm ms-auto",
              "is-loading": _ctx.isLoading,
              onClick: _ctx.remove,
              type: "danger"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Remove ")
              ]),
              _: 1
            }, 8, ["is-loading", "onClick"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_InputGroup, {
        label: "Name",
        "is-required": true
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: _normalizeClass(["form-control", {'is-invalid': _ctx.v$.callType.name.$error}]),
            placeholder: "Dress Rehearsal",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.callType.name) = $event))
          }, null, 2), [
            [_vModelText, _ctx.callType.name]
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_InputGroup, {
        label: "Color to appear on calendar",
        "is-required": true
      }, {
        default: _withCtx(() => [
          false
            ? (_openBlock(), _createBlock(_component_Selector2, {
                key: 0,
                options: _ctx.colorOptions,
                placeholder: "Select a color",
                modelValue: _ctx.callType.color,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.callType.color) = $event))
              }, null, 8, ["options", "modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_ColorSelector, {
            modelValue: _ctx.callType.color,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.callType.color) = $event))
          }, null, 8, ["modelValue"]),
          (_ctx.v$.callType.color.$error)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Please set a color."))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    footer: _withCtx(() => [
      (_ctx.mode === 'add')
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            onClick: _ctx.add,
            type: "primary",
            "is-loading": _ctx.isLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode("Add Call Type")
            ]),
            _: 1
          }, 8, ["onClick", "is-loading"]))
        : (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            onClick: _ctx.update,
            type: "primary",
            "is-loading": _ctx.isLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode("Save Changes")
            ]),
            _: 1
          }, 8, ["onClick", "is-loading"]))
    ]),
    _: 1
  }, 512))
}