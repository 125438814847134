
import {defineComponent} from "vue";
import CommunicateToolbar from "@/layout/toolbars/entity/CommunicateToolbar.vue";
import SchedulingToolbar from "@/layout/toolbars/entity/SchedulingToolbar.vue";
import AvailabilityToolbar from "@/layout/toolbars/entity/AvailabilityToolbar.vue";
import PeopleToolbar from "@/layout/toolbars/entity/PeopleToolbar.vue";
import SettingsToolbar from "@/layout/toolbars/entity/SettingsToolbar.vue";
import UserSettingsToolbar from "@/layout/toolbars/UserSettingsToolbar.vue";
import UserCommunicationsToolbar from "@/layout/toolbars/user/UserCommunicationsToolbar.vue";
import UserMembershipsToolbar from "@/layout/toolbars/user/UserMembershipsToolbar.vue";
import UserScheduleToolbar from "@/layout/toolbars/user/UserScheduleToolbar.vue";
import ProductionsToolbar from "@/layout/toolbars/entity/ProductionsToolbar.vue";
import {useUser} from "@/store/pinia/userStore";

export default defineComponent({
  setup(){
    const userStore = useUser()
    return {userStore}
  },
  components: {
    ProductionsToolbar,
    UserScheduleToolbar,
    UserMembershipsToolbar,
    UserCommunicationsToolbar,
    UserSettingsToolbar,
    SettingsToolbar, PeopleToolbar, AvailabilityToolbar, SchedulingToolbar, CommunicateToolbar
  },
  methods: {
    isEntityRoute(routeName: string) {
      return this.currentRoute.indexOf(routeName) > -1 && (this.currentRoute.indexOf('production') > -1 || this.currentRoute.indexOf('organization') > -1)
    },
    isRoute(routeName: string) {
      return this.currentRoute.indexOf(routeName) > -1
    },
  },
  computed: {
    title(): string {
      if(this.isEntityMode) {
        return this.userStore.currentEntityName || ''
      }
      if(this.currentRoute.indexOf('user-settings') > -1) { return 'Profile & Settings'}
      return (this.$route.meta.pageName as string).split('User ')[1]
    },
    currentRoute(): string {
      return typeof this.$route.name === "string" ? this.$route.name : ''
    },
    isEntityMode(): boolean {
      return this.currentRoute.indexOf('production') > -1 || this.currentRoute.indexOf('organization') > -1
    },
    isUserSettingsMode(): boolean {
      return this.currentRoute.indexOf('user-settings') > -1
    },
    hideToolbarDesktop(): boolean {
      return this.currentRoute.indexOf('overview') > -1
    },
  }
})
