
import {defineComponent} from "vue";
import {bus} from "@/modules/eventBus";
import Button from "@/components/UI/Button.vue";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";

export default defineComponent({
  setup(){
    const userStore = useUser();
    const applicationStore = useApplication();
    return {applicationStore, userStore}
  },
  components: {Button},
  methods: {
    invokeAddCommunication() {
      bus.emit('modal:communications:add')
    },
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    }
  }
})
