import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "application d-flex flex-column flex-root" }
const _hoisted_2 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_3 = {
  class: "content d-flex flex-column flex-column-fluid",
  id: "kt_content"
}
const _hoisted_4 = {
  class: "post d-flex flex-column-fluid",
  id: "kt_post"
}
const _hoisted_5 = { class: "container-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsideBase = _resolveComponent("AsideBase")!
  const _component_HeaderBase = _resolveComponent("HeaderBase")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_UserMobileToolbar = _resolveComponent("UserMobileToolbar")!
  const _component_EntityMobileToolbar = _resolveComponent("EntityMobileToolbar")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_scrolltop = _resolveComponent("scrolltop")!
  const _component_GlobalModals = _resolveComponent("GlobalModals")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["header-fixed header-tablet-and-mobile-fixed", {'toolbar-enabled toolbar-fixed': _ctx.showToolbar}]),
    style: {"--kt-toolbar-height":"55px","--kt-toolbar-height-tablet-and-mobile":"55px"}
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_AsideBase, { class: "d-none" }),
        _createElementVNode("div", {
          class: _normalizeClass([{'wrapper': !_ctx.isOnboarding && !_ctx.isSettingsMode}, "d-flex flex-column flex-row-fluid"]),
          id: "kt_wrapper"
        }, [
          (!_ctx.isOnboarding && !_ctx.isSettingsMode)
            ? (_openBlock(), _createBlock(_component_HeaderBase, {
                key: 0,
                style: {"transition":"all .5s linear"}
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Toolbar),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_router_view)
              ])
            ])
          ]),
          (!_ctx.isEntityMode && !_ctx.isOnboarding && !_ctx.isSettingsMode)
            ? (_openBlock(), _createBlock(_component_UserMobileToolbar, {
                key: 1,
                class: "fixed-bottom bg-light d-flex d-md-none"
              }))
            : _createCommentVNode("", true),
          (_ctx.isEntityMode && !_ctx.isOnboarding && !_ctx.isSettingsMode)
            ? (_openBlock(), _createBlock(_component_EntityMobileToolbar, {
                key: 2,
                class: "fixed-bottom bg-light d-flex d-md-none"
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_Footer, { class: "fixed-bottom d-none d-md-flex" })
        ], 2)
      ])
    ]),
    _createVNode(_component_scrolltop),
    _createVNode(_component_GlobalModals)
  ], 2))
}