<template>
  <el-select placeholder="Select a color" :model-value="modelValue" @change="emitter">
    <el-option
        v-for="color in colorOptions"
        :key="color.id"
        :label="color.text"
        :value="color.id"
    >
      <span class="" :class="`badge badge-${getCallColor(color.id)}`">{{ color.text }}</span>
    </el-option>
  </el-select>
</template>

<script>
import {getColorName} from "@/models/scheduling/CallType";

export default {
  name: "ColorSelector",
  emits: ['update:modelValue'],
  props: ['modelValue'],
  data() {
    return {
      colorOptions: [
        {
          text: 'Default',
          id: 0
        }, {
          text: 'Red',
          id: 1
        }, {
          text: 'Rust',
          id: 2
        }, {
          text: 'Pink',
          id: 3
        }, {
          text: 'Dark Blue',
          id: 4
        }, {
          text: 'Light Blue',
          id: 5
        }, {
          text: 'Yellow',
          id: 6
        }, {
          text: 'Orange',
          id: 7
        }, {
          text: 'Gray',
          id: 8
        }, {
          text: 'Purple',
          id: 9
        }, {
          text: 'Green',
          id: 10
        }, {
          text: 'Light Pink',
          id: 11
        }, {
          text: 'Dark Blue',
          id: 12
        }, {
          text: 'Dark Purple',
          id: 13
        }, {
          text: 'Light Yellow',
          id: 14
        }, {
          text: 'Light Orange',
          id: 15
        }, {
          text: 'Azure',
          id: 16
        }
      ],
      val: 0
    }
  },
  methods: {
    getCallColor(color) {
      return getColorName(color);
    },
    emitter(event) {
      this.$emit('update:modelValue', event)
    }
  }
}
</script>

<style scoped>

</style>
