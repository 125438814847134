
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import InputGroup from "@/components/UI/InputGroup.vue";
import {Conflict} from "@/models/user/Conflict";
import {ConflictService} from "@/services/ConflictService";
import {ConflictCommand} from "@/models/user/ConflictCommand";
import Button from "@/components/UI/Button.vue";
import Swal from "sweetalert2";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {bus} from "@/modules/eventBus";
import {UserConflictViewModel} from "@/models/UserConflictViewModel";
import {useToast} from "vue-toastification";
import {ProblemDetails} from "@/models/ProblemDetails";
import {alertController, isPlatform, toastController} from "@ionic/vue";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  setup(_, {emit}) {
    const modal = ref<InstanceType<typeof ModalBase>>();
    const toast = useToast();
    const v$ = useVuelidate()
    let displayCollisions = ref(false)
    let entity = ref<Conflict>({
      conflictId: '', end: moment.utc().add(1, 'h').format(), name: '', isFlexible: false,
      isPrivate: false, start: moment.utc().format(), allDay: false
    } as Conflict);
    let mode = ref<string>('add');
    const toggleUpdate = (_entity: Conflict) => {
      entity.value = _entity;
      mode.value = 'update';
      v$.value.$reset()
      displayCollisions.value = false
      modal.value?.toggleOpen();
    }
    const toggleAdd = () => {
      let currentDate = moment.utc().format('YYYY-MM-DD')
      entity.value = {
        conflictId: '',
        end: moment(`${currentDate} 9:00 PM`, 'YYYY-MM-DD h:mm A').format(),
        name: '',
        isFlexible: false,
        isPrivate: false,
        allDay: false,
        start: moment(`${currentDate} 6:00 PM`, 'YYYY-MM-DD h:mm A').format()
      } as Conflict;
      mode.value = 'add';
      displayCollisions.value = false
      v$.value.$reset()
      modal.value?.toggleOpen();
    }
    const toggleClosed = () => {
      modal.value?.toggleClosed();
    }
    const emitAdded = (conflict: Conflict) => {
      emit('added', conflict)
      modal.value?.toggleClosed();
    }
    return {
      modal,
      toggleUpdate,
      toggleAdd,
      mode,
      entity,
      toggleClosed,
      emitAdded,
      displayCollisions,
      v$,
      toast
    }
  },
  components: {Button, InputGroup, ModalBase},
  emits: ['added'],
  mounted() {
    bus.on('modal:conflicts:updateExternal', (conflictId) => {
      this.isFetching = true
      this.isLoading = true
      ConflictService.get(conflictId as string).then(response => {
        this.toggleUpdate(response.data)
        this.isFetching = false
        this.isLoading = false
      })
    })
    bus.on('modal:conflicts:add', () => {
      this.toggleAdd()
    })
    bus.on('modal:conflicts:update', (conflict) => {
      this.toggleUpdate(conflict as Conflict)
    })
    bus.on('modal:conflicts:retrieve', (conflictId) => {
      this.retrieve(conflictId as string)
    })
  },
  data() {
    return {
      isLoading: false,
      isFetching: false,
      collisions: [] as Array<UserConflictViewModel>
    }
  },
  validations() {
    return {
      entity: {
        name: {required},
        start: {required},
        end: {required, validConflictDateRange},
      }
    }
  },
  methods: {
    /**
     * Add Conflict
     * @description Add operation for a new conflict
     */
    add() {
      ConflictService.add({
        end: this.entity.end,
        isFlexible: this.entity.isFlexible,
        isPrivate: this.entity.isPrivate,
        name: this.entity.name,
        start: this.entity.start,
        comment: this.entity.comment,
        allDay: this.entity.allDay
      } as ConflictCommand).then(response => {
        if (isPlatform('capacitor')) {
          toastController.create({
            message: 'Conflict has been added',
            duration: 2000
          }).then((I) => {
            I.present()
          });
        }else {
          this.toast.success("Conflict Added")
        }
        this.toggleClosed()
        bus.emit('modal:conflicts:added', response.data)
        bus.emit('modal:scheduling:updated')
        WebAnalytics.trackFlexible('Added Conflict', {
          isFlexible: this.entity.isFlexible,
          isPrivate: this.entity.isPrivate,
          allDay: this.entity.allDay,
          hasComment: this.entity.comment !== ''
        })
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      }).finally(() => {
        this.isLoading = false
      })
    },
    /**
     * Update Conflict
     * @description Update operation for an existing conflict
     */
    update() {
      //this.entity.end = moment(this.entity.end).utc().toDate()
      //this.entity.start = moment(this.entity.start).utc().toDate()
      ConflictService.update(this.entity).then(() => {
        this.toast.success("Conflict Updated")
        bus.emit('modal:scheduling:updated')
        this.toggleClosed()
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
        WebAnalytics.trackFlexible('Updated Conflict', {})
      }).finally(() => {
        this.isLoading = false
      })
    },
    /**
     * Commit Conflict Operation to API
     * This will optionally validate the conflict before adding or updating it.
     * @param isNewConflict Set to true to add a new conflict. False will update an existing conflict.
     * @param skipValidation Set to true to skip validation and just add or update the conflict.
     */
    commit(isNewConflict: boolean, skipValidation: boolean) {
      this.v$.entity.$touch()
      if (!this.v$.entity.$invalid) {
        this.isLoading = true
        if (skipValidation) {
          if (isNewConflict) {
            this.add()
          } else {
            this.update()
          }
        } else {
          this.validate().then(response => {
            if (response.data.length == 0) {
              if (isNewConflict) {
                this.add()
              } else {
                this.update()
              }
            } else {
              this.isLoading = false
              this.collisions = response.data
              this.showCollisions()
            }
          })
        }
      }
    },
    showCollisions() {
      this.displayCollisions = true
    },
    hideCollisions() {
      this.displayCollisions = false
    },
    validate() {
      return ConflictService.validate(this.entity.start, this.entity.end)
    },
    getFriendlyDateTime(date: Date) {
      return moment(date).format('MMM Do h:mm a')
    },
    retrieve(conflictId: string) {
      ConflictService.get(conflictId).then(response => {
        this.toggleUpdate(response.data)
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      })
    },
    remove() {
      if(isPlatform('capacitor')){
        alertController
            .create({
              header: 'Are you sure?',
              message: 'Removing this conflict',
              buttons: [
                {
                  text: 'Cancel',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: () => {},
                },
                {
                  text: 'Okay',
                  id: 'confirm-button',
                  handler: () => {
                    this.doRemoveConflict()
                  },
                },
              ],
            }).then(alert => {
              alert.present()
        })
      } else {
        Swal.fire({
          title: "Are you sure?",
          confirmButtonText: 'Remove Conflict',
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Keep Conflict'
        }).then(response => {
          if (response.isConfirmed) {
            this.doRemoveConflict()
          }
        })
      }
    },
    doRemoveConflict(){
      ConflictService.remove(this.entity.conflictId).then(() => {
        if(isPlatform('capacitor')){
          toastController.create({
            message: 'Conflict removed',
            duration: 2000
          }).then(toast => {
            toast.present()
          });
        } else {
          this.toast.success('Conflict Removed')
        }
        bus.emit('modal:scheduling:updated')
        WebAnalytics.trackFlexible('Removed Conflict', {})
        this.toggleClosed()
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      })
    },
  },
  computed: {
    title(): string {
      return this.mode === 'add' ? 'Add Conflict' : `Manage Conflict`
    },
    allDay: {
      get() {
        return this.entity.allDay
      },
      set(allDay: boolean) {
        // We will manually reset the times accordingly
        this.entity.allDay = allDay

        if (allDay) {
          this.startTime = '0:00 AM'
          this.endTime = '11:59 PM'
        } else {
          this.startDate = moment(this.entity.start).format('YYYY-MM-DD')
          this.startTime = '6:00 PM'
          this.endTime = '9:00 PM'
        }
      }
    },
    startDate: {
      get() {
        return moment(this.entity.start).format('YYYY-MM-DD')
      },
      set(date: string) {
        let newDate = moment(date).format('YYYY-MM-DD')
        let currentTime = moment(this.entity.start).format('HH:mm')
        this.entity.start = moment(`${newDate} ${currentTime}`).format()

        if (!this.entity.allDay) {
          let currentEndTime = moment(this.entity.end).format('HH:mm')
          this.entity.end = moment(`${newDate} ${currentEndTime}`).format()
        }
      }
    },
    endDate: {
      get() {
        return moment(this.entity.end).format('YYYY-MM-DD')
      },
      set(date: string) {
        let newDate = moment(date).format('YYYY-MM-DD')
        let currentTime = moment(this.entity.end).format('HH:mm')
        this.entity.end = moment(`${newDate} ${currentTime}`).format()
      }
    },
    startTime: {
      get() {
        return moment(this.entity.start).format('h:mm A')
      },
      set(time: string) {
        let currentDate = moment(this.entity.start).format('YYYY-MM-DD')
        let newTime = moment(time, 'h:mm A').format('h:mm A')
        this.entity.start = moment(`${currentDate} ${newTime}`, 'YYYY-MM-DD h:mm A').format()
      }
    },
    endTime: {
      get() {
        return moment(this.entity.end).format('h:mm A')
      },
      set(time: string) {
        let currentDate = moment(this.entity.end).format('YYYY-MM-DD')
        let newTime = moment(time, 'h:mm A').format('h:mm A')
        this.entity.end = moment(`${currentDate} ${newTime}`, 'YYYY-MM-DD h:mm A').format()
      }
    }
  }
})

const validConflictDateRange = (end, vm) => {
  // console.log('validConflictDateRange END', end)
  // console.log('validConflictDateRange VM', vm)
  return moment(vm.start).isBefore(moment(end));
};

