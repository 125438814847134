
import {defineComponent} from "vue";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";
import Button from "@/components/UI/Button.vue";
import {bus} from "@/modules/eventBus";

export default defineComponent({
  components: {Button},
  setup() {
    const userStore = useUser();
    const applicationStore = useApplication()
    return {applicationStore, userStore}
  },
  methods: {
    goTo(routeName: string) {
      this.$router.push({name: `${this.entityType.toLowerCase()}-settings-${routeName}`})
    },
    addVenue() {
      bus.emit('modals:venue:add')
    },
    addCallType() {
      bus.emit('modals:calltype:add')
    }
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    },
    entityType(): string {
      return this.isOrganization ? 'Organization' : 'Production'
    }
  },
})
