
import "@fullcalendar/core/vdom";
import FullCalendar, {CalendarOptions, EventClickArg} from '@fullcalendar/vue3';
import {defineComponent, ref,} from "vue";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import {CallService} from "@/services/CallService";
import {CallCalendarEvent} from "@/models/scheduling/CallCalendarEvent";
import {Resource} from "@fullcalendar/resource-common";
import ViewConflictModal from "@/components/modals/ViewConflictModal.vue";
import {bus} from "@/modules/eventBus";

export default defineComponent({
  setup() {
    const conflictViewerModal = ref<InstanceType<typeof ViewConflictModal>>();
    const toggleModal = (_event: CallCalendarEvent) => {
      conflictViewerModal.value?.toggleModal(_event)
    }
    const calendar = ref<InstanceType<typeof FullCalendar>>();
    const refetch = () => {
      calendar.value?.getApi().refetchEvents();
      calendar.value?.getApi().refetchResources();
    }
    return {conflictViewerModal, toggleModal, calendar, refetch}
  },
  components: {FullCalendar, ViewConflictModal},
  props: ['events'],
  data() {
    return {
      calOptions: {
        themeSystem: 'bootstrap',
        plugins: [resourceTimelinePlugin],
        initialView: "resourceTimelineWeek",
        aspectRatio: 3,
        views: {
          resourceTimelineDay: {buttonText: "conflicts by day"},
          resourceTimelineWeek: {buttonText: "conflicts by week"},
          resourceTimelineMonth: {buttonText: "conflicts by month"}
        },
        headerToolbar: {
          left: 'title',
          right: 'today prev,next,resourceTimelineDay,resourceTimelineTenDay,resourceTimelineMonth'
        },
        eventClick: (args: EventClickArg) => {
          const event = args.event;
          const call = {
            start: event.start,
            end: event.end,
            title: event.title === '' ? 'Multiple Conflicts' : event.title,
            description: event.title === '' ? 'One or more people have conflicts during this timeframe.' : event.extendedProps.description,
            isConflict: event.extendedProps.isConflict,
            conflictId: event.extendedProps.conflictId,
            isFlexible: event.extendedProps.isFlexible,
            isPrivate: event.extendedProps.isPrivate,
            resourceId: event.extendedProps.resourceId,
            classNames: event.extendedProps.classNames
          } as CallCalendarEvent;
          this.toggleModal(call);
        },
        eventSources: [this.fetchConflicts],
        resources: this.fetchResources,
        resourceAreaHeaderContent: 'People by Group',
        resourceAreaWidth: '15%',
        resourceGroupLaneClassNames: 'fc-group-header',
        slotMinWidth: 100,
        eventMaxStack: 1,
        resourcesInitiallyExpanded: false,
        datesSet: () => this.print()
      } as CalendarOptions,
      conflicts: [] as Array<CallCalendarEvent>,
      resources: [] as Array<Resource>
    }
  },
  methods: {
    print() {
      bus.emit('availability:update:activeStart', this.calendar?.getApi().view.title)
    },
    redraw() {
      this.calendar?.getApi().refetchEvents();
    },
    fetchResources(fetchInfo, successCallback, failureCallback) {
      CallService.fetchProductionResources(this.productionId).then(response => {
        this.resources = response.data
        successCallback(response.data)
      }).catch(err => {
        failureCallback(err)
      })
    },
    fetchConflicts(fetchInfo, successCallback, failureCallback) {
      CallService.fetchProductionAvailability(this.productionId, fetchInfo.startStr, fetchInfo.endStr).then(response => {
        this.conflicts = response.data
        this.conflicts.forEach(conflict => {
          if (conflict.isFlexible) {
            conflict.classNames = 'fc-h-purple-event'
          } else {
            conflict.classNames = 'fc-h-bloodred-event'
          }
        })
        successCallback(response.data)
      }).catch((err) => {
        console.log(err)
        failureCallback(err)
      })
    }
  },
  computed: {
    productionId(): string {
      return this.$route.params['productionId'] as string
    }
  },
  mounted() {
    bus.on('availability:actions:previous', () => {
      this.calendar?.getApi().prev()
    })
    bus.on('availability:actions:next', () => {
      this.calendar?.getApi().next()
    })
    bus.on('availability:actions:today', () => {
      this.calendar?.getApi().today()
    })
    bus.on('availability:actions:changeView', (viewType) => {
      this.calendar?.getApi().changeView(viewType as string)
    })
    bus.on('availability:refresh', () => {
      this.refetch()
    })
  }
});
