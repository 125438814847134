
import {defineComponent} from "vue";
import TopbarBase from "@/layout/topbar/TopbarBase.vue";
import UserHeaderNav from "@/layout/header/UserHeaderNav.vue";
import EntityHeaderNav from "@/layout/header/EntityHeaderNav.vue";
import EntityPicker from "@/components/UI/EntityPicker.vue";
import EmblemLogo from "@/components/EmblemLogo.vue";

export default defineComponent({
  components: {EmblemLogo, EntityPicker, EntityHeaderNav, UserHeaderNav, TopbarBase},
  mounted() {
    window.KTMenu.init();
  },
  methods: {
    goHome() {
      this.$router.push({name: 'user-overview'})
    }
  },
  computed: {
    currentRoute(): string {
      return typeof this.$route.name === "string" ? this.$route.name : ''
    },
    isEntityMode(): boolean {
      return this.currentRoute.indexOf('production') > -1 || this.currentRoute.indexOf('organization') > -1
    }
  }
});
