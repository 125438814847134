
import {defineComponent, PropType} from "vue";
import {NotificationItem} from "@/models/notifications/Notification";
import moment from "moment";
import {useUser} from "@/store/pinia/userStore";
import {bus} from "@/modules/eventBus";

export default defineComponent({
  setup() {
    const userStore = useUser();
    return {userStore}
  },
  props: {
    notification: {
      type: Object as PropType<NotificationItem>,
      required: true
    }
  },
  methods: {
    view() {
      this.markRead()
      let invitations = this.userStore.invitations.find(x => x.entityName === this.entityName)
      bus.emit('modals:invitations:view', invitations)
    },
    markRead() {
      this.userStore.setNotificationAsRead(this.notification.notificationId)
    },
  },
  computed: {
    relativeCreationDate() {
      return moment(this.notification.createdOn).calendar()
    },
    entityName(): string {
      if (this.notification.productionName) {
        return this.notification.productionName
      } else if (this.notification.organizationName) {
        return this.notification.organizationName
      }
      return 'Unknown Entity Name'
    }
  }
})
