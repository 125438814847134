
import {defineComponent} from "vue";
import {bus} from "@/modules/eventBus";
import Button from "@/components/UI/Button.vue";
import {CallCalendarEvent} from "@/models/scheduling/CallCalendarEvent";
import {getColorName} from "@/models/scheduling/CallType";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";

export default defineComponent({
  setup() {
    const userStore = useUser();
    const applicationStore = useApplication();
    return {applicationStore, userStore}
  },
  components: {Button},
  data() {
    return {
      currentViewType: 'dayGridMonth',
      calls: [] as Array<CallCalendarEvent>,
      filters: {
        callTypes: [] as Array<string>,
        showFor: 'me',
        showConflicts: true,
        productions: [] as Array<string>
      },
      activeStart: ''
    }
  },
  mounted() {
    bus.on('calendar:update:activeStart', (activeStart) => this.setActiveStart(activeStart as string))
    bus.on('calendar:filters:update', (calls) => this.setCalls(calls as Array<CallCalendarEvent>))
  },
  methods: {
    invokeScheduleCall() {
      bus.emit('modal:scheduling:add')
    },
    previous() {
      bus.emit('calendar:actions:previous')
    },
    next() {
      bus.emit('calendar:actions:next')
    },
    today() {
      bus.emit('calendar:actions:today')
    },
    changeView(viewType: string) {
      bus.emit('calendar:actions:changeView', viewType)
      this.currentViewType = viewType
    },
    setActiveStart(activeStart: string) {
      this.activeStart = activeStart
    },
    setCalls(calls: Array<CallCalendarEvent>) {
      this.calls = calls
      this.filters.callTypes = this.availableCallTypes.map(call => call.id)
      this.filters.productions = this.availableProductions.map(call => call.id)
    },
    getCallTypeColor(color: number) {
      return getColorName(color);
    },
    filtersChanged() {
      bus.emit('calendar:filters:change', this.filters)
    },
    invokeScheduleSettings() {
      bus.emit('modals:schedule:settings', 'venues')
    },
    conflictsFilterChanged() {
      bus.emit('calendar:filters:change:conflicts', this.filters.showConflicts)
    },
  },
  computed: {
    availableCallTypes(): Array<CallTypeFilter> {
      let set = new Set();
      let callTypes = [] as Array<CallTypeFilter>

      this.calls.forEach(call => {
        if (!set.has(call.callTypeId)) {
          set.add(call.callTypeId)
          callTypes.push({
            id: call.callTypeId,
            color: call.callTypeColor,
            name: call.callTypeName
          } as unknown as CallTypeFilter)
        }
      })
      return callTypes
    },
    availableProductions(): Array<ProductionFilter> {
      let set = new Set();
      let productions = [] as Array<ProductionFilter>

      this.calls.forEach(call => {
        if (!set.has(call.productionId)) {
          set.add(call.productionId)
          productions.push({
            id: call.productionId,
            color: 'default',
            name: call.productionName
          } as unknown as ProductionFilter)
        }
      })
      return productions
    },
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
  }
})

export interface CallTypeFilter {
  id: string
  color: string
  name: string
}

export interface ProductionFilter {
  id: string
  color: string
  name: string
}

