
import {defineComponent} from "vue";

export default defineComponent({
  computed: {
    currentRoute(): string {
      return typeof this.$route.name === "string" ? this.$route.name : ''
    },
    isEntityMode(): boolean {
      return this.currentRoute.indexOf('production') > -1 || this.currentRoute.indexOf('organization') > -1
    },
  },
  methods: {
    navigate(routeName: string){
      this.$router.push({name: routeName})
    },
    isRoute(routeName: string){
      return this.currentRoute.indexOf(routeName) > -1
    }
  }
})
