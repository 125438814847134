
import {defineComponent} from "vue";
import {ProductionMembership} from "@/models/productions/ProductionMembership";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";
import {MembershipStatus} from "@/models/enums/MembershipStatus";
import UpArrowSvg from "@/svg/UpArrowSvg.vue";
import DownArrowSvg from "@/svg/DownArrowSvg.vue";

export default defineComponent({
  components: {DownArrowSvg, UpArrowSvg},
  setup() {
    const userStore = useUser();
    const applicationStore = useApplication();
    return {applicationStore, userStore}
  },
  methods: {
    jump(productionId: string) {
      if (this.$route.name) {
        this.$router.push({
          name: 'production-overview',
          params: {productionId: productionId}
        })
      }
    },
    jumpToManage(organizationId: string) {
      this.$router.push({name: 'organization-settings-general', params: {organizationId: organizationId}})
    },
    jumpToManageMobile(organizationId: string) {
      this.$router.push({name: 'organization-settings-home', params: {organizationId: organizationId}})
    },
    getProductions(organizationId: string): Array<ProductionMembership> {
      return this.userStore.productions.filter(prod => prod.organizationId === organizationId && prod.status === MembershipStatus.Active)
    },
    getOrganizationName(organizationId: string) {
      if (this.userStore.organizations.filter(org => org.organizationId === organizationId).length > 0) {
        return this.userStore.organizations.filter(org => org.organizationId === organizationId)[0].name
      } else if (this.userStore.productions.filter(prod => prod.organizationId === organizationId).length > 0) {
        return this.userStore.productions.filter(prod => prod.organizationId === organizationId)[0].organizationName
      }
      return 'Organization'
    },
    isProduction(organizationEntity: any): boolean {
      return organizationEntity?.productionId;
    }
  },
  computed: {
    organizations(): Set<any> {
      // Get all organizations the user directly is member of
      let organizations = new Set(this.userStore.organizations.filter(x => x.status === MembershipStatus.Active).map(org => org.organizationId))

      // Get all organizations via production
      this.userStore.productions.filter(x => x.status === MembershipStatus.Active).forEach(prod => {
        organizations.add(prod.organizationId)
      })
      // Return the unique set
      return organizations
    },
  }
})
