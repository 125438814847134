
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import {CommunicationViewModel} from "@/models/communications/CommunicationViewModel";
import moment from "moment";
import {bus} from "@/modules/eventBus";
import {CommunicationService} from "@/services/CommunicationService";
import {CommunicationAttachment} from "@/models/communications/CommunicationAttachment";
import {Communication} from "@/models/communications/Communication";
import {WebAnalytics} from "@/modules/webAnalytics";
import {CommunicationType} from "@/models/enums/CommunicationType";

export default defineComponent({
  setup() {
    const modal = ref<InstanceType<typeof ModalBase>>();
    let communication = ref<Communication>({content: '', attachments: [] as Array<CommunicationAttachment>, lastUpdated: new Date()} as Communication);
    let isLoading = ref<boolean>(true);
    const toggleModal = (_communication: CommunicationViewModel) => {
      CommunicationService.getCommunication(_communication.communicationId).then(response => {
        communication.value = response.data
      }).finally(() => {
        isLoading.value = false
        WebAnalytics.trackFlexible('Viewed Communication', {
          type: _communication.type === CommunicationType.Announcement ? 'Announcement' : 'Text',
          attachmentsCount: _communication.attachments.length
        })
      })
      modal.value?.toggle()
    }
    return {modal, toggleModal, communication, isLoading}
  },
  methods: {
    getFriendlyDateTime(date: Date): string {
      return moment(date).format('MMMM Do YYYY h:mm a').toString();
    },
    retrieve(communicationId: string) {
      CommunicationService.getCommunicationView(communicationId).then(response => {
        this.toggleModal(response.data)
      })
    },
    getAttachmentLink(communicationAttachmentId: string) {
      CommunicationService.getAttachment(communicationAttachmentId).then(response => {
        setTimeout(() => {
          window.open(response.data.url, '_blank');
        })
        WebAnalytics.trackFlexible('Opened Communication Attachment', {})
      })
    },
    convertToMB(bytes: number) {
      const decimals = 2
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  },
  mounted() {
    bus.on('modal:communications:viewer:view', (communication) => {
      this.toggleModal(communication as CommunicationViewModel)
    })
    bus.on('modal:communications:viewer:retrieve', (callId) => {
      this.retrieve(callId as string)
    })
  },
  components: {ModalBase}
})
