
import {defineComponent, ref} from "vue";
import ModalBase from "../ModalBase.vue";
import moment from "moment";
import {CallCalendarEvent} from "@/models/scheduling/CallCalendarEvent";
import {bus} from "@/modules/eventBus";
import {getColorName} from "@/models/scheduling/CallType";
import {CallService} from "@/services/CallService";
import { useUser } from "@/store/pinia/userStore";
import { ConflictService } from "@/services/ConflictService";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  components: {ModalBase},
  setup() {
    const modal = ref<InstanceType<typeof ModalBase>>();
    let call = ref<CallCalendarEvent>({
      title: '', callTypeColor: 0
    } as CallCalendarEvent);
    const toggleModal = (_event: CallCalendarEvent) => {
      call.value = _event;
      modal.value?.toggleOpen()
      WebAnalytics.trackFlexible('Viewed Call', {
        productionId: _event.productionId,
        isConflict: _event.isConflict
      })
    }
    const toggleClosed = () => {
      modal.value?.toggleClosed()
    }
    const userStore = useUser();
    return {modal, toggleModal, call, toggleClosed, userStore}
  },
  emits: ['update', 'remove'],
  methods: {
    retrieve(entityId: string, isConflict: boolean) {
      // console.log('viewCallModel retrieve', callId)
      if(isConflict){
        ConflictService.getAsCalendarEvent(entityId).then(response => {
          this.toggleModal(response.data)
        })
      } else {
        CallService.getCallView(entityId).then(response => {
          this.toggleModal(response.data)
        })
      }
    },
    getFriendlyDateTime(date: string): string {
      return moment(date).format('MMMM Do YYYY h:mm a').toString();
    },
    updateCall() {
      this.toggleClosed()
      bus.emit('modal:scheduling:update', this.call)
    },
    updateConflict() {
      this.toggleClosed()
      bus.emit('modal:conflicts:updateExternal', this.call.conflictId)
    },
    updateEntity() {
      if (!this.canEdit) return;
      if (this.isOwnConflict) {
        this.updateConflict()
      } else {
        this.updateCall()
      }
    },
    getCallColor(color: number): string {
      return getColorName(color);
    }
  },
  mounted() {
    bus.on('modal:calls:viewer:view', (call) => {
      this.toggleModal(call as CallCalendarEvent)
    })
    bus.on('modal:calls:viewer:retrieve', (payload) => {
      const p = payload as any
      this.retrieve(p.entityId as string, p.isConflict)
    })
  },
  computed: {
    isOwnConflict(): boolean {
      return this.call.isConflict && this.call.resourceId?.toUpperCase() === this.userStore.profile.accountId.toUpperCase()
    },
    title(): string {
      if (!this.call.isConflict) {
        return this.call.title
      } else if (!this.isOwnConflict) {
        return `${this.call.title} (${this.call.accountName})`
      } else {
        return this.call.title
      }
    },
    date(): string {
      const startDate = moment(this.call.start).format('MM/DD/YYYY')
      const endDate = moment(this.call.end).format('MM/DD/YYYY')
      const isSameDay = startDate === endDate
      if (isSameDay) {
        return `${moment(this.call.start).format('MMMM Do h:mm a')} - ${moment(this.call.end).format('h:mm a')}`
      }
      return `${moment(this.call.start).format('MMMM Do h:mm a')} - ${moment(this.call.end).format('MMMM Do h:mm a')}`
    },
    canEdit(): boolean {
      return this.isOwnConflict || (!this.call.isConflict && this.userStore.canManage(this.call.productionId))
    },
  },
})
