
import {defineComponent} from "vue";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";

export default defineComponent({
  setup() {
    const userStore = useUser();
    const applicationStore = useApplication();
    return {applicationStore, userStore}
  },
  methods: {
    navigate(routeName: string) {
      if (this.isProduction) {
        this.$router.push({name: `production-${routeName}`, params: this.$route.params})
      } else {
        this.$router.push({name: `organization-${routeName}`, params: this.$route.params})
      }
    },
    isRoute(routeName: string) {
      return this.currentRoute.indexOf(routeName) > -1
    },
    returnHome() {
      this.$router.push({name: 'user-overview'});
    }
  },
  computed: {
    isProduction() {
      if (this.$route.name) {
        return this.$route.name.toString().indexOf('production') > -1 &&
            this.$route.name.toString().indexOf('productions') === -1
      } else {
        return false
      }
    },
    currentRoute(): string {
      return typeof this.$route.name === "string" ? this.$route.name : ''
    },
  }
})
