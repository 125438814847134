import {PhoneNumberCommand, VerifyPhoneCommand} from "@/models/user/PhoneNumberCommand";
import {apiClient} from "@/modules/apiClient";
import {AxiosResponse} from "axios";
import {PhoneNumber} from "@/models/user/PhoneNumber";

export const PhoneNumberService = {
    add(command: PhoneNumberCommand): Promise<AxiosResponse<PhoneNumber>>{
        return apiClient.post('/phonenumbers', command)
    },
    update(command: PhoneNumberCommand): Promise<AxiosResponse>{
        return apiClient.put('/phonenumbers', command)
    },
    remove(phoneNumberId: string): Promise<AxiosResponse>{
        return apiClient.delete(`/phonenumbers/${phoneNumberId}`)
    },
    makePrimary(phoneNumberId: string): Promise<AxiosResponse>{
        return apiClient.post(`/phonenumbers/${phoneNumberId}/primary`)
    },
    requestVerification(phoneNumberId: string): Promise<AxiosResponse>{
        return apiClient.get(`/phonenumbers/${phoneNumberId}/verify`)
    },
    verify(command: VerifyPhoneCommand): Promise<AxiosResponse>{
        return apiClient.put(`/phonenumbers/${command.phoneNumberId}/verify`, command)
    }
}
