
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import Button from "@/components/UI/Button.vue";
import {PhoneNumber} from "@/models/user/PhoneNumber";
import {VerifyPhoneCommand} from "@/models/user/PhoneNumberCommand";
import useVuelidate from "@vuelidate/core";
import {PhoneNumberService} from "@/services/PhoneNumberService";
import Swal from "sweetalert2";
import {required} from "@vuelidate/validators";
import {bus} from "@/modules/eventBus";
import {useUser} from "@/store/pinia/userStore";
import { createInput } from '@formkit/vue'
import OneTimeCode from '@/components/inputs/OneTimeCode.vue'

export default defineComponent({
  components: {Button, ModalBase},
  setup() {
    const modal = ref<InstanceType<typeof ModalBase>>();
    const values = ref({})
    const toggleClosed = () => {
      modal.value?.toggleClosed();
    }
    const toggleOpen = () => {
      modal.value?.toggleOpen();
    }
    const userStore = useUser();
    const otp = createInput(OneTimeCode, {
      props: ['digits'],
    })
    return {v$: useVuelidate(), modal, toggleClosed, toggleOpen, userStore, otp, values}
  },
  data() {
    return {
      isLoading: false,
      entity: {} as PhoneNumber,
      verificationEntity: {} as VerifyPhoneCommand
    }
  },
  validations() {
    return {
      verificationEntity: {
        verificationCode: {required}
      }
    }
  },
  mounted() {
    bus.on('modals:phoneverify:show', (phoneNumber) => {
      this.invoke(phoneNumber as PhoneNumber)
    })
  },
  methods: {
    verify() {
      this.v$.verificationEntity.$touch()
      if (!this.v$.verificationEntity.$invalid) {
        this.isLoading = true
        PhoneNumberService.verify(this.verificationEntity).then(() => {
          bus.emit('modals:phoneverify:verified')
          this.isLoading = false
          this.toggleClosed()
          Swal.fire('Verified Phone Number Added')
          this.userStore.fetchUser()
        }).catch(() => {
          Swal.fire('The code you entered was not valid.')
          this.isLoading = false
        })
      }
    },
    formatPhone(phoneNumber: string) {
      try {
        return phoneNumber.replace(/[^0-9]/g, '')
            .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4')
      } catch {
        return ''
      }
    },
    invoke(phoneNumber: PhoneNumber) {
      this.verificationEntity = {phoneNumberId: phoneNumber.phoneNumberId} as VerifyPhoneCommand;
      this.entity = phoneNumber;
      this.toggleOpen();
      this.requestPhoneVerification(phoneNumber.phoneNumberId)
    },
    requestPhoneVerification(phoneNumberId: string) {
      PhoneNumberService.requestVerification(phoneNumberId)
    }
  },
  computed: {
    title(): string {
      return `Verify ${this.formatPhone(this.entity.number)}`
    }
  }
})
