
import {defineComponent} from "vue";

export default defineComponent({
    methods: {
        navigate(routeName: string) {
            this.$router.push({name: routeName})
        },
        isRoute(routeName: string) {
            return this.currentRoute.indexOf(routeName) > -1
        }
    },
    computed: {
        currentRoute(): string {
            return typeof this.$route.name === "string" ? this.$route.name : ''
        }
    }
})
