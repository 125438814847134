<template>
  <CallModal ref="callModal"></CallModal>
  <ScheduleSettingsModal/>
  <ConflictModal ref="conflictModal"></ConflictModal>
  <ViewCallModal ref="viewCallModal"></ViewCallModal>
  <CommunicationViewerModal ref="communicationViewerModal"></CommunicationViewerModal>
  <ChangePasswordModal ref="changePasswordModal"/>
  <RequestModal ref="requestModal"/>
  <VerifyPhoneModal ref="verifyPhoneModal"/>
  <VenueModal ref="venueModal"></VenueModal>
  <CallTypeModal ref="callTypeModal"></CallTypeModal>
  <ExceptionModal></ExceptionModal>
  <CreateProductionModal/>
  <SendFeedbackModal/>
  <InvitationModal/>
  <PhotoCropperModal ref="photoCropperModal"/>
</template>

<script>
import CallModal from "@/components/modals/CallModal";
import ConflictModal from "@/components/modals/ConflictModal";
import CommunicationViewerModal from "@/components/modals/CommunicationViewerModal";
import ViewCallModal from "@/components/modals/ViewCallModal";
import VerifyPhoneModal from "@/components/modals/VerifyPhoneModal";
import ChangePasswordModal from "@/components/modals/ChangePasswordModal";
import RequestModal from "@/components/modals/RequestModal";
import VenueModal from "@/components/modals/VenueModal.vue";
import CallTypeModal from "@/components/modals/CallTypeModal.vue";
import ExceptionModal from "@/components/ExceptionModal.vue";
import SendFeedbackModal from "@/components/modals/SendFeedbackModal";
import CreateProductionModal from "@/components/modals/CreateProductionModal";
import ScheduleSettingsModal from "@/components/modals/ScheduleSettingsModal";
import InvitationModal from "@/components/modals/InvitationModal.vue";
import PhotoCropperModal from "@/components/modals/PhotoCropperModal.vue";

export default {
  name: "GlobalModals",
  components: {
    ScheduleSettingsModal,
    CreateProductionModal,
    SendFeedbackModal,
    RequestModal,
    InvitationModal,
    PhotoCropperModal,
    ChangePasswordModal, VerifyPhoneModal, ViewCallModal, CallModal, ConflictModal, CommunicationViewerModal, VenueModal, CallTypeModal, ExceptionModal}
}
</script>
