
import {bus} from "@/modules/eventBus";
import {defineComponent, ref} from 'vue';
import {ProblemDetails} from "@/models/ProblemDetails";
import ModalBase from "@/components/ModalBase.vue";
import Button from "@/components/UI/Button.vue";

export default defineComponent({
  setup() {
    const modal = ref<InstanceType<typeof ModalBase>>();
    const exceptionData = ref<ProblemDetails>({} as ProblemDetails);
    const invoke = (_exceptionData: ProblemDetails) => {
      exceptionData.value = _exceptionData
      modal.value?.toggleOpen()
    }
    const toggleClosed = () => {
      modal.value?.toggleClosed()
    }
    return {modal, exceptionData, invoke, toggleClosed}
  },
  components: {
    Button,
    ModalBase
  },
  mounted() {
    bus.on('modal:exception:show', (exceptionData) => {
      this.invoke(exceptionData as ProblemDetails)
    })
  }
})
