
import {defineComponent, PropType} from "vue";
import {NotificationItem} from "@/models/notifications/Notification";
import moment from "moment";
import {bus} from "@/modules/eventBus";
import {useUser} from "@/store/pinia/userStore";

export default defineComponent({
  setup() {
    const userStore = useUser();
    return {userStore}
  },
  props: {
    notification: {
      type: Object as PropType<NotificationItem>,
      required: true
    }
  },
  methods: {
    viewCall(){
      this.markRead()
      bus.emit('modal:calls:viewer:retrieve', {entityId: this.notification.callId, isConflict: false})
    },
    viewConflict(){
      this.markRead()
      bus.emit('modal:calls:viewer:retrieve', {entityId: this.notification.conflictId, isConflict: true})
    },
    markRead(){
      this.userStore.setNotificationAsRead(this.notification.notificationId)
    },
    friendlyDate(date: Date) {
      return moment(date).format('MM/DD')
    },
    friendlyTime(date: Date) {
      return moment(date).format('h:mma')
    }
  },
  computed: {
    conflictDateTime() {
      if (this.notification.conflictStart) {
        return `${this.friendlyDate(this.notification.conflictStart)} at ${this.friendlyTime(this.notification.conflictStart)}`
      }
      return ''
    },
    relativeCreationDate() {
      return moment(this.notification.createdOn).calendar()
    }
  }
})
