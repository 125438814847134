
import {defineComponent} from "vue";
import Button from "@/components/UI/Button.vue";
import {bus} from "@/modules/eventBus";
import {CallCalendarEvent} from "@/models/scheduling/CallCalendarEvent";
import {getColorName} from "@/models/scheduling/CallType";
import {CallTypeFilter} from "@/layout/toolbars/entity/SchedulingToolbar.vue";

export default defineComponent({
  components: {Button},
  data() {
    return {
      currentViewType: 'dayGridMonth',
      calls: [] as Array<CallCalendarEvent>,
      conflicts: [] as Array<CallCalendarEvent>,
      filters: {
        callTypes: [] as Array<string>,
        showConflicts: true
      },
      activeStart: ''
    }
  },
  mounted() {
    bus.on('calendar:update:activeStart', (activeStart) => this.setActiveStart(activeStart as string))
    bus.on('calendar:filters:update', (calls) => this.setCalls(calls as Array<CallCalendarEvent>))
    bus.on('calendar:filters:update:conflicts', (conflicts) => this.setConflicts(conflicts as Array<CallCalendarEvent>))
  },
  methods: {
    previous() {
      bus.emit('calendar:actions:previous')
    },
    next() {
      bus.emit('calendar:actions:next')
    },
    today() {
      bus.emit('calendar:actions:today')
    },
    changeView(viewType: string) {
      bus.emit('calendar:actions:changeView', viewType)
      this.currentViewType = viewType
    },
    setActiveStart(activeStart: string) {
      this.activeStart = activeStart
    },
    setCalls(calls: Array<CallCalendarEvent>) {
      this.calls = calls
      this.filters.callTypes = this.availableCallTypes.map(call => call.id)
    },
    setConflicts(conflicts: Array<CallCalendarEvent>) {
      this.conflicts = conflicts
    },
    getCallTypeColor(color: number) {
      try {
        return getColorName(color);
      } catch (err) {
        console.log('err', err)
      }
    },
    filtersChanged() {
      bus.emit('calendar:filters:change', this.filters)
    },
    conflictsFilterChanged() {
      bus.emit('calendar:filters:change:conflicts', this.filters.showConflicts)
    },
    invokeAddConflict(){
      bus.emit('modal:conflicts:add')
    },
  },
  computed: {
    availableCallTypes(): Array<CallTypeFilter> {
      let set = new Set();
      let callTypes = [] as Array<CallTypeFilter>

      this.calls.forEach(call => {
        if(!set.has(call.callTypeId)){
          set.add(call.callTypeId)
          callTypes.push({
            id: call.callTypeId,
            color: call.callTypeColor,
            name: call.callTypeName
          } as unknown as CallTypeFilter)
        }
      })

      return callTypes
    },
  }
})
