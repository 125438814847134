
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import Button from "@/components/UI/Button.vue";
import InputGroup from "@/components/UI/InputGroup.vue";
import {Venue} from "@/models/venues/Venue";
import {VenueSpace} from "@/models/venues/VenueSpace";
import {VenueService, VenueSpaceService} from "@/services/VenueService";
import {AddVenueCommand} from "@/models/venues/VenueCommand";
import Swal from "sweetalert2";
import {AddVenueSpaceCommand} from "@/models/venues/AddVenueSpaceCommand";
import Utilities from "@/utilities/Utilities";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {bus} from "@/modules/eventBus";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  setup(_, {emit}) {
    const modal = ref<InstanceType<typeof ModalBase>>();
    let venue = ref<Venue>({venueId: '', address: '', name: '', notes: '', spaces: [], discriminator: ''} as Venue);
    let mode = ref<string>('add');
    let spaceMode = ref<string>('off'); // off | add | update
    const v$ = useVuelidate()
    const invokeAdd = () => {
      venue.value = {venueId: '', address: '', name: '', notes: '', spaces: [], discriminator: ''} as Venue
      mode.value = 'add'
      spaceMode.value = 'off'
      v$.value.$reset()
      modal.value?.toggle()
    }
    const invokeUpdate = (_venue: Venue) => {
      venue.value = _venue
      mode.value = 'update'
      spaceMode.value = 'off'
      v$.value.$reset()
      modal.value?.toggle()
    }
    const toggleClosed = () => {
      modal.value?.toggle();
    }
    const emitAdded = (_venue: Venue) => {
      emit('added', _venue)
    }
    return {modal, venue, mode, invokeAdd, invokeUpdate, toggleClosed, emitAdded, spaceMode, v$}
  },
  components: {InputGroup, Button, ModalBase},
  emits: ['added'],
  data() {
    return {
      space: {} as VenueSpace,
      isLoading: false
    }
  },
  validations() {
    return {
      venue: {
        name: {required},
        address: {required}
      }
    }
  },
  mounted() {
    bus.on('modals:venue:add', this.invokeAdd)
    bus.on('modals:venue:update', venue => {
      this.invokeUpdate(venue as Venue)
    })
  },
  methods: {
    addVenue() {
      this.v$.venue.$touch()
      if (!this.v$.venue.$invalid) {
        this.isLoading = true
        if (this.isOrganization) {
          VenueService.add({
            name: this.venue.name,
            address: this.venue.address,
            notes: this.venue.notes,
            organizationId: this.organizationId
          } as AddVenueCommand).then(response => {
            this.venue.venueId = response.data.venueId
            bus.emit('modals:venue:added', response.data)
            //console.log('push refetch')
            bus.emit('modals:venue:refetch')
            bus.emit('onboarding:production:fetch')
            this.toggleAddSpace()
            this.mode = 'update'
            this.isLoading = false
          }).finally(() => {
            this.isLoading = false
            WebAnalytics.trackFlexible('Created Venue', {
              organizationId: this.organizationId
            })
          })
        } else {
          VenueService.add({
            name: this.venue.name,
            address: this.venue.address,
            notes: this.venue.notes,
            productionId: this.productionId
          } as AddVenueCommand).then(response => {
            this.venue.venueId = response.data.venueId
            bus.emit('modals:venue:added', response.data)
            //console.log('push refetch')
            bus.emit('modals:venue:refetch')
            bus.emit('onboarding:production:fetch')
            this.mode = 'update'
            this.toggleAddSpace()
            this.isLoading = false
          }).finally(() => {
            this.isLoading = false
            WebAnalytics.trackFlexible('Created Venue', {
              productionId: this.productionId
            })
          })
        }
      }
    },
    updateVenue() {
      this.v$.venue.$touch()
      if (!this.v$.venue.$invalid) {
        this.isLoading = true
        VenueService.update(this.venue).then(() => {
          Swal.fire('Venue Updated')
          bus.emit('modals:venue:updated')
          //console.log('push refetch')
          bus.emit('modals:venue:refetch')
          this.toggleClosed()
          this.isLoading = false
        }).finally(() => {
          this.isLoading = false
          WebAnalytics.trackFlexible('Updated Venue', {})
        })
      }
    },
    removeVenue() {
      Swal.fire({
        title: "Are you sure?",
        text: 'This venue and all its spaces will be removed.',
        confirmButtonText: 'Remove Venue',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Keep Venue'
      }).then(response => {
        if (response.isConfirmed) {
          this.isLoading = true
          VenueService.remove(this.venue.venueId).then(() => {
            bus.emit('modals:venue:removed', this.venue.venueId)
            this.toggleClosed()
            Swal.fire('Venue Removed')
          }).catch(() => {
            Swal.fire('One or more calls are scheduled to spaces for this venue. Please update those calls, and try again.')
          }).finally(() => {
            this.isLoading = false
            WebAnalytics.trackFlexible('Removed Venue', {})
          })
        }
      })
    },
    addSpace() {
      this.isLoading = true
      VenueSpaceService.add({
        notes: this.space.notes,
        name: this.space.name,
        venueId: this.venue.venueId
      } as AddVenueSpaceCommand).then(response => {
        this.venue.spaces.push(response.data)
        //console.log('push refetch')
        bus.emit('modals:venue:refetch')
        this.space = {} as VenueSpace
        this.spaceMode = 'off'
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      }).finally(() => {
        WebAnalytics.trackFlexible('Added Venue Space', {})
      })
    },
    updateSpace() {
      this.isLoading = true
      VenueSpaceService.update(this.space).then(() => {
        Swal.fire('Space Updated')
        //console.log('push refetch')
        bus.emit('modals:venue:refetch')
        this.space = {} as VenueSpace
        this.spaceMode = 'off'
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      }).finally(() => {
        WebAnalytics.trackFlexible('Updated Venue Space', {})
      })
    },
    removeSpace(space: VenueSpace) {
      this.isLoading = true
      VenueSpaceService.remove(space.venueId, space.venueSpaceId).then(() => {
        Utilities.removeObject(this.venue.spaces, 'venueSpaceId', space.venueSpaceId)
        Swal.fire('Space Removed')
      }).catch(() => {
        Swal.fire('One or more calls are scheduled to this space. Please update those calls, and try again.')
      }).finally(() => {
        this.isLoading = false
        WebAnalytics.trackFlexible('Removed Venue Space', {})
      })
    },
    toggleAddSpace() {
      this.space = {} as VenueSpace
      this.spaceMode = 'add'
    },
    toggleUpdateSpace(space: VenueSpace) {
      this.space = space
      this.spaceMode = 'update'
    },
    toggleCancelSpace() {
      this.space = {} as VenueSpace
      this.spaceMode = 'off'
    }
  },
  computed: {
    title(): string {
      if (this.mode === 'add') {
        return 'Step 1: Give your venue a name'
      } else if (this.spaceMode === 'off') {
        return `Manage ${this.venue.name}`
      } else if (this.space.name && this.spaceMode !== 'add') {
        return `Manage ${this.space.name}`
      }
      return `Step 2: Add a space to ${this.venue.name}`
    },
    description(): string {
      if (this.mode === 'add') {
        return 'on the next screen, you can add spaces (rooms) to this venue, which can be used for scheduling'
      } else if (this.mode !== 'add' && this.spaceMode === 'add') {
        return 'a rehearsal or performance space can be used when scheduling calls'
      }
      return ''
    },
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    }
  }
})
