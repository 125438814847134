
import {defineComponent, PropType} from "vue";
import {NotificationItem} from "@/models/notifications/Notification";
import moment from "moment";
import {useUser} from "@/store/pinia/userStore";

export default defineComponent({
  setup() {
    const userStore = useUser();
    return {userStore}
  },
  props: {
    notification: {
      type: Object as PropType<NotificationItem>,
      required: true
    }
  },
  methods: {
    view() {
      this.markRead()
      if(this.isProduction){
        this.$router.push({name: 'production-people', params: { productionId: this.notification.productionId }})
      } else {
        this.$router.push({name: 'organization-people', params: { organizationId: this.notification.organizationId }})
      }
    },
    markRead() {
      this.userStore.setNotificationAsRead(this.notification.notificationId)
    },
  },
  computed: {
    relativeCreationDate() {
      return moment(this.notification.createdOn).calendar()
    },
    entityName(): string {
      if (this.notification.productionName) {
        return this.notification.productionName
      } else if (this.notification.organizationName) {
        return this.notification.organizationName
      }
      return 'Unknown Entity Name'
    },
    isProduction(){
      return !!this.notification.productionName;
    }
  }
})
