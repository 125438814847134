import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e85b52de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder flex-nowrap justify-content-evenly px-6 bottom-nav" }
const _hoisted_2 = { class: "nav-item mt-4 mb-4" }
const _hoisted_3 = { class: "nav-item" }
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", _hoisted_2, [
      _createElementVNode("a", {
        class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('user-overview')}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigate('user-overview')))
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["fa-home fa-2x", {
          'fas text-white': _ctx.isRoute('user-overview'),
          fal: !_ctx.isRoute('user-overview'),
        }])
        }, null, 2)
      ], 2)
    ]),
    _createElementVNode("li", _hoisted_3, [
      _createElementVNode("a", {
        class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('user-communications')}]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigate('user-communications')))
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["fa-bullhorn fa-2x", {
          'fas text-white': _ctx.isRoute('user-communications'),
          fal: !_ctx.isRoute('user-communications'),
        }])
        }, null, 2)
      ], 2)
    ]),
    _createElementVNode("li", _hoisted_4, [
      _createElementVNode("a", {
        class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('user-schedule')}]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navigate('user-schedule')))
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["fa-calendar-alt fa-2x", {
          'fas text-white': _ctx.isRoute('user-schedule'),
          fal: !_ctx.isRoute('user-schedule'),
        }])
        }, null, 2)
      ], 2)
    ]),
    _createElementVNode("li", _hoisted_5, [
      _createElementVNode("a", {
        class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('user-memberships')}]),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.navigate('user-memberships')))
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["fa-chart-network fa-2x", {
          'fas text-white': _ctx.isRoute('user-memberships'),
          fal: !_ctx.isRoute('user-memberships'),
        }])
        }, null, 2)
      ], 2)
    ]),
    _createElementVNode("li", _hoisted_6, [
      _createElementVNode("a", {
        class: _normalizeClass(["nav-link text-active-white", {'active': _ctx.isRoute('user-settings-general')}]),
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.navigate('user-settings-general')))
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["fa-user-cog fa-2x", {
          'fas text-white': _ctx.isRoute('user-settings-general'),
          fal: !_ctx.isRoute('user-settings-general'),
        }])
        }, null, 2)
      ], 2)
    ])
  ]))
}