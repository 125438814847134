
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import InputGroup from "@/components/UI/InputGroup.vue";
import Button from "@/components/UI/Button.vue";
import {UserService} from "@/services/UserService";
import {RequestAccessCommand} from "@/models/user/RequestAccessCommand";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {bus} from "@/modules/eventBus";
import {useToast} from "vue-toastification";
import {isPlatform, toastController} from "@ionic/vue";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  components: {Button, InputGroup, ModalBase},
  setup() {
    const modal = ref<InstanceType<typeof ModalBase>>();
    let requestCommand = ref<RequestAccessCommand>({StageCode: ''} as RequestAccessCommand);
    let isLoading = ref(false);
    const toggleModal = () => {
      requestCommand.value = {StageCode: ''}
      isLoading.value = false;
      modal.value?.toggle();
    }
    return {modal, toggleModal, requestCommand, isLoading, v$: useVuelidate(), toast: useToast()}
  },
  validations() {
    return {
      requestCommand: {
        StageCode: {required}
      }
    }
  },
  mounted() {
    bus.on('modals:request:show', this.toggleModal)
  },
  methods: {
    requestAccess() {
      this.v$.requestCommand.$touch()
      if (!this.v$.requestCommand.$invalid) {
        this.isLoading = true;
        UserService.requestAccess(this.requestCommand).then(() => {
          if (isPlatform('capacitor')) {
            toastController.create({
              message: 'Your access request has been sent',
              duration: 2000
            }).then(toast => {
              toast.present()
            });
          } else {
            this.toast.success('Your access request has been sent')
          }
          bus.emit('modals:request:complete')
          this.toggleModal()
        }).catch(() => {
          if (isPlatform('capacitor')) {
            toastController.create({
              message: 'Looks like you entered an invalid StageCode',
              duration: 2000
            }).then(toast => {
              toast.present()
            });
          } else {
            this.toast.error('Looks like you entered an invalid StageCode');
          }
        }).finally(() => {
          this.isLoading = false
          WebAnalytics.trackFlexible('Requested Access', {})
        })
      }
    }
  }
})
