
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import {bus} from "@/modules/eventBus";
import {Venue} from "@/models/venues/Venue";
import {CallType, getColorName} from "@/models/scheduling/CallType";
import {VenueService} from "@/services/VenueService";
import {CallTypeService} from "@/services/CallTypeService";
import Button from "@/components/UI/Button.vue";
import Utilities from "@/utilities/Utilities";
import VenueSettings from "@/components/SelfContained/VenueSettings.vue";
import CallTypeSettings from "@/components/SelfContained/CallTypeSettings.vue";

export default defineComponent({
  components: {CallTypeSettings, VenueSettings, Button, ModalBase},
  setup() {
    const modal = ref<InstanceType<typeof ModalBase>>();
    const tab = ref<string>('');
    const toggleModal = () => {
      bus.emit('modals:production:fetch')
      modal.value?.toggleOpen()
    }
    const toggleClosed = () => {
      modal.value?.toggleClosed()
    }
    return {modal, toggleModal, tab, toggleClosed};
  },
  data() {
    return {
      callTypesLoading: true,
      venuesLoading: true,
      venues: [] as Array<Venue>,
      callTypes: [] as Array<CallType>,
    }
  },
  mounted() {
    bus.on('modals:schedule:settings', (tab) => {
      this.fetch()
      this.tab = tab as string
      this.toggleModal()
    })
    bus.on('modals:calltype:added', (callType) => {
      this.handleCallTypeAdded(callType as CallType)
    })
    bus.on('modals:calltype:removed', (callTypeId) => {
      this.handleCallTypeRemoved(callTypeId as string)
    })
    bus.on('modals:venue:added', (venue) => {
      this.handleVenueAdded(venue as Venue)
    })
    bus.on('modals:venue:removed', (venueId) => {
      this.handleVenueRemoved(venueId as string)
    })
  },
  methods: {
    fetch() {
      if (this.isOrganization) {
        VenueService.fetchOrganization(this.organizationId).then(response => {
          this.venues = response.data
          this.venuesLoading = false
        })
      } else {
        VenueService.fetchProduction(this.productionId).then(response => {
          this.venues = response.data
          this.venuesLoading = false
        })
        CallTypeService.listProduction(this.productionId).then(response => {
          this.callTypes = response.data
          this.callTypesLoading = false
        })
      }
    },
    addCallType() {
      //this.toggleClosed()
      bus.emit('modals:calltype:add')
    },
    updateCallType(callType: CallType) {
      //this.toggleClosed()
      bus.emit('modals:calltype:update', callType)
    },
    addVenue() {
      //this.toggleClosed()
      bus.emit('modals:venue:add')
    },
    updateVenue(venue: Venue) {
      //this.toggleClosed()
      bus.emit('modals:venue:update', venue)
    },
    getCallTypeColor(color: number) {
      return getColorName(color);
    },
    handleVenueAdded(venue: Venue) {
      this.venues.push(venue)
    },
    handleCallTypeAdded(callType: CallType) {
      this.callTypes.push(callType)
    },
    handleCallTypeRemoved(callTypeId: string) {
      Utilities.removeObject(this.callTypes, 'callTypeId', callTypeId)
    },
    handleVenueRemoved(venueId: string){
      Utilities.removeObject(this.venues, 'venueId', venueId)
    }
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    }
  }
})
