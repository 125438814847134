import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 my-8 fw-bolder flex-nowrap" }
const _hoisted_2 = {
  key: 0,
  class: "d-none nav-item"
}
const _hoisted_3 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = {
  key: 2,
  class: "nav-item"
}
const _hoisted_6 = {
  key: 3,
  class: "nav-item"
}
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = {
  key: 4,
  class: "nav-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_ctx.isProduction)
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('overview')}]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigate('overview')))
          }, "Overview", 2)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isProduction)
      ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('communications')}]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigate('communications')))
          }, "Communicate", 2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("li", _hoisted_4, [
      _createElementVNode("a", {
        class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('schedule')}]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navigate('schedule')))
      }, "Schedule", 2)
    ]),
    (_ctx.userStore.canManage(_ctx.userStore.currentEntity) && _ctx.isProduction)
      ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('availability')}]),
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.navigate('availability')))
          }, "Availability", 2)
        ]))
      : _createCommentVNode("", true),
    (_ctx.userStore.canManage(_ctx.userStore.currentEntity) && !_ctx.isProduction)
      ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('productions')}]),
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.navigate('productions')))
          }, "Productions", 2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("li", _hoisted_7, [
      _createElementVNode("a", {
        class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('people')}]),
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.navigate('people')))
      }, "People", 2)
    ]),
    (_ctx.userStore.canManage(_ctx.userStore.currentEntity))
      ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('settings')}]),
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.navigate('settings-general')))
          }, "Settings", 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}