
import {defineComponent} from "vue";
import {NotificationItem} from "@/models/notifications/Notification";
import NotifyManagerConflictNotification from "@/components/UI/Notifications/NotifyManagerConflictNotification.vue";
import NotifyMemberConflictNotification from "@/components/UI/Notifications/NotifyMemberConflictNotification.vue";
import CallCancelledNotification from "@/components/UI/Notifications/CallCancelledNotification.vue";
import NewCommunicationNotification from "@/components/UI/Notifications/NewCommunicationNotification.vue";
import CallRescheduledNotification from "@/components/UI/Notifications/CallRescheduledNotification.vue";
import NewInvitationNotification from "@/components/UI/Notifications/NewInvitationNotification.vue";
import NewRequestNotification from "@/components/UI/Notifications/NewRequestNotification.vue";
import InvitationResponseNotification from "@/components/UI/Notifications/InvitationResponseNotification.vue";
import RequestResponseNotification from "@/components/UI/Notifications/RequestResponseNotification.vue";
import moment from "moment";
import {useUser} from "@/store/pinia/userStore";

export default defineComponent({
    setup() {
        const userStore = useUser()
        return {userStore}
    },
    components: {
        RequestResponseNotification,
        InvitationResponseNotification,
        NewRequestNotification,
        NewInvitationNotification,
        CallRescheduledNotification,
        NewCommunicationNotification,
        CallCancelledNotification, NotifyMemberConflictNotification, NotifyManagerConflictNotification
    },
    methods: {
        fetch() {
            this.userStore.fetchNotifications()
        },
        markAllAsRead() {
            this.userStore.markAllNotificationsRead()
        }
    },
    data() {
        return {
            notifications: [] as Array<NotificationItem>,
            isLoading: false
        }
    },
    computed: {
        recentNotifications(): Array<NotificationItem> {
            // Return all unread PLUS any others up to 3 days old
            return this.userStore.notifications.filter(n => {
                return moment(n.createdOn).isAfter(moment().subtract(3, "days").endOf('day')) || !n.read
            })
        },
    }
});
