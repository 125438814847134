<template>
  <div class="hover-scroll-overlay-y my-2 my-lg-5 pe-lg-n1" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu" data-kt-scroll-offset="5px">
    <!--begin::Menu-->
    <div class="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold" id="#kt_aside_menu" data-kt-menu="true">
      <div class="menu-item py-2">
        <a class="menu-link active menu-center">
										<span class="menu-icon me-0">
											<i class="fal fa-home fs-2"></i>
										</span>
          <span class="menu-title">Home</span>
        </a>
      </div>
      <div class="menu-item py-2">
        <a class="menu-link menu-center">
										<span class="menu-icon me-0">
											<i class="fal fa-calendar-alt fs-2"></i>
										</span>
          <span class="menu-title">Schedule</span>
        </a>
      </div>
      <div class="menu-item py-2">
        <a class="menu-link menu-center">
										<span class="menu-icon me-0">
											<i class="fal fa-bullhorn fs-2"></i>
										</span>
          <span class="menu-title">Communications</span>
        </a>
      </div>
      <div class="menu-item py-2">
        <a class="menu-link menu-center">
										<span class="menu-icon me-0">
											<i class="fal fa-user fs-2"></i>
										</span>
          <span class="menu-title">Profile</span>
        </a>
      </div>
      <div class="menu-item py-2">
        <a class="menu-link menu-center">
										<span class="menu-icon me-0">
											<i class="fal fa-sign-out-alt fs-2"></i>
										</span>
          <span class="menu-title" style="white-space: nowrap;">Sign Out</span>
        </a>
      </div>

    </div>
    <!--end::Menu-->
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  components: {},
  // type inference enabled
});
</script>
