
import { defineComponent, ref } from "vue";
import ModalBase from "../ModalBase.vue";
import InputGroup from "@/components/UI/InputGroup.vue";
import Button from "@/components/UI/Button.vue";
import {required, sameAs} from "@vuelidate/validators";
import {UserService} from "@/services/UserService";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import {bus} from "@/modules/eventBus";

export default defineComponent({
  components: {Button, InputGroup, ModalBase },
  setup() {
    const modal = ref<InstanceType<typeof ModalBase>>();
    const toggleOpen = () => {
      modal.value?.toggleOpen();
    };
    const toggleClosed = () => {
      modal.value?.toggleClosed();
    };
    return { modal, toggleOpen, toggleClosed, v$: useVuelidate() };
  },

  validations(){
    return{
      entity: {
        currentPassword: {required},
        newPassword: {required},
        confirmNewPassword: sameAs(this.entity.newPassword)
      }
    }
  },
  data() {
    return {
      entity: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      isLoading: false
    }
  },
  mounted(){
    bus.on('modals:changepassword:show', this.toggleOpen)
  },
  methods: {
    changePassword() {
      this.v$.entity.$touch()
      if (!this.v$.entity.$invalid) {
        this.isLoading = true
        UserService.changePassword({
          currentPassword: this.entity.currentPassword,
          newPassword: this.entity.newPassword
        }).then(() => {
          this.isLoading = false
          this.toggleClosed()
          Swal.fire("Password Updated")
        })
      }
    }
  }
});
