import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-header border-0" }
const _hoisted_2 = { class: "d-flex flex-column" }
const _hoisted_3 = { class: "fw-bold fs-4" }
const _hoisted_4 = {
  key: 0,
  class: "fst-italic fs-4"
}
const _hoisted_5 = { class: "d-flex flex-column justify-content-end align-self-baseline" }
const _hoisted_6 = {
  key: 1,
  class: "badge badge-light-info text-white"
}
const _hoisted_7 = {
  key: 2,
  class: "badge badge-light-warning text-white"
}
const _hoisted_8 = { class: "d-flex flex-column" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "" }
const _hoisted_11 = {
  key: 0,
  class: "fs-3 border-bottom mt-2 pb-2"
}
const _hoisted_12 = {
  key: 1,
  class: "fw-lighter fs-5 mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalBase = _resolveComponent("ModalBase")!

  return (_openBlock(), _createBlock(_component_ModalBase, {
    title: "Call Details",
    size: "mw-650px modal-dialog-centered",
    "has-header": true,
    "has-toolbar": true,
    ref: "modal"
  }, {
    toolbar: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", {
              class: _normalizeClass(["fs-1 fw-bolder text-wrap", {'text-hover-primary cursor-pointer': _ctx.canEdit}]),
              style: {"text-wrap":"normal","width":"100%"},
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateEntity && _ctx.updateEntity(...args)))
            }, _toDisplayString(_ctx.title), 3),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.date), 1),
            (!_ctx.call.isConflict)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.call.productionName), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (!_ctx.call.isConflict)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(`badge text-white badge-${_ctx.getCallColor(_ctx.call.callTypeColor)}`)
              }, _toDisplayString(_ctx.call.callTypeName), 3))
            : _createCommentVNode("", true),
          (_ctx.call.isConflict && _ctx.call.isFlexible)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Flexible Conflict"))
            : _createCommentVNode("", true),
          (_ctx.call.isConflict && !_ctx.call.isFlexible)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Conflict"))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_8, [
        (_ctx.call.description !== '')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "fs-6",
              innerHTML: _ctx.call.description
            }, null, 8, _hoisted_9))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        (!_ctx.call.isConflict)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, "location"))
          : _createCommentVNode("", true),
        (!_ctx.call.isConflict)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", null, _toDisplayString(_ctx.call.venueSpaceName) + " @ " + _toDisplayString(_ctx.call.venueName), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.call.venueAddress), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.call.venueSpaceNotes), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 512))
}