import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f9247ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder flex-nowrap justify-content-evenly px-6 bottom-nav" }
const _hoisted_2 = {
  key: 0,
  class: "nav-item mt-4 mb-4"
}
const _hoisted_3 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_4 = {
  key: 2,
  class: "nav-item"
}
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = {
  key: 3,
  class: "nav-item"
}
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "btn btn-flex align-items-center bg-hover-white bg-hover-opacity-10 p-0" }
const _hoisted_9 = { class: "cursor-pointer symbol symbol-30px" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  class: "symbol-label bg-primary text-inverse-warning fw-bolder fs-7"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_ctx.isProduction)
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('overview')}]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigate('overview')))
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["fa-grip-horizontal fa-2x", {
          'fas text-white': _ctx.isRoute('overview'),
          fal: !_ctx.isRoute('overview'),
        }])
            }, null, 2)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isProduction)
      ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('communications') && _ctx.isProduction}]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigate('communications')))
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["fa-bullhorn fa-2x", {
          'fas text-white': _ctx.isRoute('communications'),
          fal: !_ctx.isRoute('communications'),
        }])
            }, null, 2)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isProduction)
      ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('schedule')}]),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navigate('schedule')))
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["fa-calendar-alt fa-2x", {
          'fas text-white': _ctx.isRoute('schedule'),
          fal: !_ctx.isRoute('schedule'),
        }])
            }, null, 2)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("li", _hoisted_5, [
      _createElementVNode("a", {
        class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('people')}]),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.navigate('people')))
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["fa-users fa-2x", {
          'fas text-white': _ctx.isRoute('people'),
          fal: !_ctx.isRoute('people'),
        }])
        }, null, 2)
      ], 2)
    ]),
    (_ctx.userStore.canManage(_ctx.userStore.currentEntity))
      ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link text-active-white me-6", {'active': _ctx.isRoute('settings')}]),
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.navigate('settings-home')))
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["fa-cogs fa-2x", {
          'fas text-white': _ctx.isRoute('settings-home'),
          fal: !_ctx.isRoute('settings-home'),
        }])
            }, null, 2)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("li", _hoisted_7, [
      _createElementVNode("div", {
        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.returnHome && _ctx.returnHome(...args))),
        class: "nav-link"
      }, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_ctx.userStore.profile.avatar)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.userStore.profile.avatar,
                  alt: "image"
                }, null, 8, _hoisted_10))
              : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.userStore.profile.firstName[0]), 1))
          ])
        ])
      ])
    ])
  ]))
}