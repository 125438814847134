
import {defineComponent, PropType} from "vue";
import {NotificationItem} from "@/models/notifications/Notification";
import moment from "moment";
import {bus} from "@/modules/eventBus";
import {useUser} from "@/store/pinia/userStore";

export default defineComponent({
  setup() {
    const userStore = useUser();
    return {userStore}
  },
  props: {
    notification: {
      type: Object as PropType<NotificationItem>,
      required: true
    }
  },
  methods: {
    viewCommunication() {
      this.markRead()
      bus.emit('modal:communications:viewer:retrieve', this.notification.communicationId)
    },
    markRead() {
      this.userStore.setNotificationAsRead(this.notification.notificationId)
    },
  },
  computed: {
    relativeCreationDate() {
      return moment(this.notification.createdOn).calendar()
    }
  }
})
