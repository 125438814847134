import {AxiosResponse} from "axios";
import {CommunicationViewModel} from "@/models/communications/CommunicationViewModel";
import {apiClient} from "@/modules/apiClient";
import {Communication} from "@/models/communications/Communication";
import {CommunicationCommand} from "@/models/communications/AddCommunicationCommand";
import {CommunicationAttachment} from "@/models/communications/CommunicationAttachment";

export const CommunicationService = {
    fetchOrganizationCommunications(organizationId: string): Promise<AxiosResponse<Array<CommunicationViewModel>>> {
        return apiClient.get(`/organizations/${organizationId}/communications`)
    },
    fetchProductionCommunications(productionId: string): Promise<AxiosResponse<Array<CommunicationViewModel>>> {
        return apiClient.get(`/productions/${productionId}/communications`)
    },
    fetchAccountCommunications(): Promise<AxiosResponse<Array<CommunicationViewModel>>> {
        return apiClient.get(`/communications`)
    },
    getCommunicationView(communicationId: string): Promise<AxiosResponse<CommunicationViewModel>>{
        return apiClient.get(`/communications/${communicationId}`)
    },
    getCommunication(communicationId: string): Promise<AxiosResponse<Communication>>{
        return apiClient.get(`/communications/${communicationId}/details`)
    },
    addCommunication(command: CommunicationCommand): Promise<AxiosResponse<CommunicationViewModel>>{
        return apiClient.post(`/communications`, command)
    },
    publishCommunication(communicationId: string): Promise<AxiosResponse>{
        return apiClient.post(`/communications/${communicationId}/publish`)
    },
    updateCommunication(communicationId: string, command: CommunicationCommand): Promise<AxiosResponse>{
        return apiClient.put(`/communications/${communicationId}`, command)
    },
    removeCommunication(communicationId: string): Promise<AxiosResponse>{
        return apiClient.delete(`/communications/${communicationId}`)
    },
    getAttachment(communicationAttachmentId: string): Promise<AxiosResponse<CommunicationAttachment>>{
      return apiClient.get(`/communications/attachments/${communicationAttachmentId}`)
    },
    uploadAttachment(communicationId: string, formData): Promise<AxiosResponse<CommunicationAttachment>> {
        return apiClient.post(`/communications/${communicationId}/attachments`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    removeAttachment(communicationAttachmentId: string): Promise<AxiosResponse> {
        return apiClient.delete(`/communications/attachments/${communicationAttachmentId}`)
    },
}
