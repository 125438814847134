
import {defineComponent} from "vue";
import UserMenu from "@/layout/topbar/partials/UserMenu.vue";
import Button from "@/components/UI/Button.vue";
import NotificationsMenu from "@/layout/topbar/partials/NotificationsMenu.vue";
import {useUser} from "@/store/pinia/userStore";
import {bus} from "@/modules/eventBus";

export default defineComponent({
  components: {NotificationsMenu, Button, UserMenu},
  setup() {
    const userStore = useUser()
    return {userStore}
  },
  methods: {
    goHome() {
      this.$router.push({name: 'user-overview'})
    },
    invokeFeedbackModal() {
      bus.emit('modals:feedback:show')
    },
    showReleaseNotes() {
      window.rnw('show')
    },
    showSupportChatWidget() {
      if (window && window.fcWidget) {
        window.fcWidget.open()
      }
    }
  },
  mounted() {
    window.rnw('init', {
      account: 'stagehub.releasenotes.io',
      selector: '.rn-badge', // change the CSS selector to apply the badge and link to
    });
  },
  computed: {
    unread(): number {
      return this.userStore.notifications.filter(x => !x.read).length
    },
    currentRoute(): string {
      return typeof this.$route.name === "string" ? this.$route.name : ''
    },
    isEntityMode(): boolean {
      return this.currentRoute.indexOf('production') > -1 || this.currentRoute.indexOf('organization') > -1
    }
  }
});
