
import {defineComponent} from "vue";
import {WebAnalytics} from "@/modules/webAnalytics";
import {bus} from "@/modules/eventBus";
import {useApplication} from "@/store/pinia/applicationStore";
import {useUser} from "@/store/pinia/userStore";
import {auth0} from "@/main";
import {isPlatform} from "@ionic/vue";

export default defineComponent({
    setup() {
        const applicationStore = useApplication()
        const userStore = useUser()
        return {userStore, applicationStore}
    },
    methods: {
        changeGlobalTab(tabName: string) {
            this.applicationStore.changeGlobalTab(tabName)
        },
        changePassword() {
            bus.emit('modals:changepassword:show')
        },
        requestAccess() {
            bus.emit('modals:request:show')
        },
        signOut() {
            WebAnalytics.logout();
            auth0.logout({
                logoutParams: {
                    returnTo: isPlatform('capacitor') ? process.env.VUE_APP_CAP_ENDSESSION : process.env.VUE_APP_AUTH_LOGOUT
                }
            })
            //Auth.Instance.signOut();
            //msalService.invokeSignOut()
        }
    },
});
