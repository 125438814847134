import {apiClient} from "@/modules/apiClient";
import {AxiosResponse} from "axios";
import {Conflict} from "@/models/user/Conflict";
import {ConflictCommand} from "@/models/user/ConflictCommand";
import {CallCalendarEvent} from "@/models/scheduling/CallCalendarEvent";
import {UserConflictViewModel} from "@/models/UserConflictViewModel";

export const ConflictService = {
    list(): Promise<AxiosResponse<Array<Conflict>>> {
        return apiClient.get('/conflicts')
    },
    get(conflictId: string): Promise<AxiosResponse<Conflict>>{
        return apiClient.get(`/conflicts/${conflictId}`)
    },
    getAsCalendarEvent(conflictId: string): Promise<AxiosResponse<CallCalendarEvent>>{
        return apiClient.get(`/conflicts/${conflictId}/calendar`)
    },
    listAsCalendar(start: string, end: string): Promise<AxiosResponse<Array<CallCalendarEvent>>> {
        return apiClient.get(`/conflicts/schedule?start=${start}&end=${end}`)
    },
    validate(start: string, end: string): Promise<AxiosResponse<Array<UserConflictViewModel>>>{
        return apiClient.post(`/conflicts/validate`, {start: start, end: end})
    },
    add(conflict: ConflictCommand): Promise<AxiosResponse<Conflict>> {
        return apiClient.post('/conflicts', conflict);
    },
    update(conflict: Conflict): Promise<AxiosResponse> {
        return apiClient.put(`/conflicts/${conflict.conflictId}`, conflict)
    },
    remove(conflictId: string): Promise<AxiosResponse> {
        return apiClient.delete(`/conflicts/${conflictId}`)
    }
}
