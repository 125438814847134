
import {defineComponent} from "vue";
import {useApplication} from "@/store/pinia/applicationStore";

export default defineComponent({

  setup(){
    const applicationStore = useApplication()
    return {applicationStore}
  },
  methods: {
    changeSettingTab(tabName: string) {
      this.applicationStore.changeUserSettingTab(tabName)
    },
    navigate(routeName: string) {
      this.$router.push({name: routeName})
    },
    returnHome() {
      this.$router.push({name: 'user-overview'});
    }
  },
  computed: {
    currentRoute(): string {
      return typeof this.$route.name === "string" ? this.$route.name : ''
    },
  }
})
