
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import {CallCalendarEvent} from "@/models/scheduling/CallCalendarEvent";
import moment from "moment";

export default defineComponent({
  setup() {
    const modal = ref<InstanceType<typeof ModalBase>>();
    let call = ref<CallCalendarEvent>({
      title: '', callTypeColor: 0
    } as CallCalendarEvent);
    const toggleModal = (_event: CallCalendarEvent) => {
      call.value = _event;
      modal.value?.toggle()
    }
    const toggleClosed = () => {
      modal.value?.toggleClosed()
    }
    return {modal, toggleModal, call, toggleClosed}
  },
  components: {ModalBase},
  methods: {
    getFriendlyDateTime(date: string): string {
      return moment(date).format('MMMM Do YYYY h:mm a').toString();
    }
  }
})
