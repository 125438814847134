export enum CommunicationType {
    Announcement = 1,
    Text = 2
}

export const getCommunicationType = (type: CommunicationType) => {
    switch (type) {
        case CommunicationType.Announcement:
            return { name: 'Announcement', class: 'success' }
        case CommunicationType.Text:
            return { name: 'Text', class: 'info' }
        default:
            return { name: 'Unknown', class: 'warning' }
    }
}
