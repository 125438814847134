
import {defineComponent} from "vue";
import Scrolltop from "@/layout/Scrolltop.vue";
import AsideBase from "@/layout/aside/AsideBase.vue";
import HeaderBase from "@/layout/header/HeaderBase.vue";
import Footer from "@/layout/Footer.vue";
import Toolbar from "@/layout/toolbars/Toolbar.vue";
import GlobalModals from "@/components/GlobalModals.vue";
import UserMobileToolbar from "@/layout/toolbars/user/UserMobileToolbar.vue";
import EntityMobileToolbar from "@/layout/toolbars/entity/EntityMobileToolbar.vue";

export default defineComponent({
  components: {UserMobileToolbar, GlobalModals, Toolbar, Footer, HeaderBase, AsideBase, Scrolltop, EntityMobileToolbar},
  data() {
    return {
      pageYOffset: 0
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.pageYOffset = window.pageYOffset
    }
  },
  computed: {
    currentRoute(): string {
      return typeof this.$route.name === "string" ? this.$route.name : ''
    },
    isEntityMode(): boolean {
      return this.currentRoute.indexOf('production') > -1 || this.currentRoute.indexOf('organization') > -1
    },
    isOnboarding(): boolean {
      return this.currentRoute.indexOf('onboarding') > -1
    },
    isSettingsMode(): boolean {
      return false
      //return this.currentRoute.indexOf('settings') > -1
    },
    isUserOnlyMode(): boolean {
      return false
      //return this.currentRoute.indexOf('user') > -1 && !this.isUserSettingsMode
    },
    showToolbar(): boolean {
      return this.currentRoute.indexOf('onboard') === -1 && !(this.currentRoute.indexOf('overview') > -1)
    },
    isTopOfPage(): boolean {
      return this.pageYOffset === 0
    },
    hideToolbarDesktop(): boolean {
      return !(this.currentRoute.indexOf('overview') > -1)
    },
  }
});
