
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import {Cropper} from 'vue-advanced-cropper'
import {bus} from "@/modules/eventBus";
import Button from "@/components/UI/Button.vue";

export default defineComponent({
  setup() {
    const modal = ref<InstanceType<typeof ModalBase>>();
    const cropperRef = ref<InstanceType<typeof Cropper>>();
    const cropperEntityRef = ref<InstanceType<typeof Cropper>>();
    const toggleClosed = () => {
      modal.value?.toggleClosed();

    }
    const toggleOpen = () => {
      modal.value?.toggleOpen()
      cropperRef.value?.reset()
      cropperRef.value?.refresh()
    }
    return {modal, toggleClosed, toggleOpen, cropperRef, cropperEntityRef}
  },
  components: {Button, ModalBase, Cropper},
  emits: ['added'],
  data() {
    return {
      isLoading: true,
      requester: 'modals:cropper:handle:mobile',
      isUserPhoto: true,
      image: {
        src: '',
        type: ''
      }
    }
  },
  methods: {
    loadImage(imageObject: any) {
      this.$log.debug('imageObject', imageObject)
      // Reference to the DOM input element
      //const {files} = event.target;
      // Ensure that you have a file before attempting to read it
      if (imageObject) {
        this.$log.debug('IS imageObject')
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(imageObject);

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        this.image = {
          src: blob,
          type: imageObject.type
        }
      } else {
        this.$log.debug('NOT imageObject')
      }
      this.isLoading = false
      this.toggleOpen()
    },
    saveImage() {
      if (this.isUserPhoto) {
        const cropperResult = this.cropperRef?.getResult() || undefined;
        if (cropperResult && cropperResult.canvas) {
          cropperResult.canvas.toBlob(blob => {
            bus.emit(this.requester, blob)
          });
        }
        this.destroy()
        this.toggleClosed()
      } else {
        this.saveImageEntity()
      }
    },
    saveImageEntity() {
      const cropperResult = this.cropperEntityRef?.getResult() || undefined;
      if (cropperResult && cropperResult.canvas) {
        cropperResult.canvas.toBlob(blob => {
          bus.emit(this.requester, blob)
        });
      }
      this.destroy()
      this.toggleClosed()
    },
    destroy() {
      // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src)
      }
    }
  },
  mounted() {
    bus.on('modals:cropper:open', (payload) => {
      this.$log.debug('modals:cropper:open triggered', payload)
      this.loadImage((payload as any).blob)
      this.requester = (payload as any).requester
      this.isUserPhoto = (payload as any).isUserPhoto
    })
  },
  unmounted() {
    this.destroy()
  },
});
