import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "toolbar d-none d-md-flex" }
const _hoisted_2 = { class: "container-fluid d-flex flex-stack justify-content-center justify-content-md-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommunicateToolbar = _resolveComponent("CommunicateToolbar")!
  const _component_SchedulingToolbar = _resolveComponent("SchedulingToolbar")!
  const _component_AvailabilityToolbar = _resolveComponent("AvailabilityToolbar")!
  const _component_ProductionsToolbar = _resolveComponent("ProductionsToolbar")!
  const _component_PeopleToolbar = _resolveComponent("PeopleToolbar")!
  const _component_SettingsToolbar = _resolveComponent("SettingsToolbar")!
  const _component_UserCommunicationsToolbar = _resolveComponent("UserCommunicationsToolbar")!
  const _component_UserScheduleToolbar = _resolveComponent("UserScheduleToolbar")!
  const _component_UserMembershipsToolbar = _resolveComponent("UserMembershipsToolbar")!
  const _component_UserSettingsToolbar = _resolveComponent("UserSettingsToolbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isEntityRoute('communications'))
        ? (_openBlock(), _createBlock(_component_CommunicateToolbar, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.isEntityRoute('schedule'))
        ? (_openBlock(), _createBlock(_component_SchedulingToolbar, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.isEntityRoute('availability'))
        ? (_openBlock(), _createBlock(_component_AvailabilityToolbar, { key: 2 }))
        : _createCommentVNode("", true),
      (_ctx.isEntityRoute('productions'))
        ? (_openBlock(), _createBlock(_component_ProductionsToolbar, { key: 3 }))
        : _createCommentVNode("", true),
      (_ctx.isEntityRoute('people'))
        ? (_openBlock(), _createBlock(_component_PeopleToolbar, { key: 4 }))
        : _createCommentVNode("", true),
      (_ctx.isRoute('organization-settings') || _ctx.isRoute('production-settings'))
        ? (_openBlock(), _createBlock(_component_SettingsToolbar, { key: 5 }))
        : _createCommentVNode("", true),
      (_ctx.isRoute('user-communications'))
        ? (_openBlock(), _createBlock(_component_UserCommunicationsToolbar, { key: 6 }))
        : _createCommentVNode("", true),
      (_ctx.isRoute('user-schedule'))
        ? (_openBlock(), _createBlock(_component_UserScheduleToolbar, { key: 7 }))
        : _createCommentVNode("", true),
      (_ctx.isRoute('user-memberships'))
        ? (_openBlock(), _createBlock(_component_UserMembershipsToolbar, { key: 8 }))
        : _createCommentVNode("", true),
      (_ctx.isUserSettingsMode)
        ? (_openBlock(), _createBlock(_component_UserSettingsToolbar, { key: 9 }))
        : _createCommentVNode("", true)
    ])
  ]))
}