
import { useApplication } from "@/store/pinia/applicationStore";
import { useUser } from "@/store/pinia/userStore";
import {defineComponent} from "vue";
import {bus} from "@/modules/eventBus";
import Button from "@/components/UI/Button.vue";

export default defineComponent({
  components: {Button},
  setup(){
    const userStore = useUser();
    const applicationStore = useApplication()
    return {applicationStore, userStore}
  },
  methods: {
    changeTab(tab: string) {
      this.applicationStore.changeUserMembershipsTab(tab);
    },
    toggleRequestAccess() {
      bus.emit('modals:request:show')
    },
  },
})
