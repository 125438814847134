
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import InputGroup from "@/components/UI/InputGroup.vue";
import Button from "@/components/UI/Button.vue";
import {UserService} from "@/services/UserService";
import {bus} from "@/modules/eventBus";
import {useToast} from "vue-toastification";
import {SendFeedbackCommand} from "@/models/SendFeedbackCommand";
import {openReplayTracker} from "@/main";
import {H} from "highlight.run";
import {ProblemDetails} from "@/models/ProblemDetails";
import {WebAnalytics} from "@/modules/webAnalytics";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default defineComponent({
  components: {Button, InputGroup, ModalBase},
  setup() {
    const modal = ref<InstanceType<typeof ModalBase>>();
    const feedback = ref<SendFeedbackCommand>({
      category: 'Idea',
      contactMe: false,
      content: '',
      replayUrl: 'https://app.openreplay.com',
      currentUrl: 'https://app.stagehub.com',
      pageName: 'Default'
    } as SendFeedbackCommand);

    const invoke = () => {
      feedback.value = {
        category: 'Idea',
        contactMe: false,
        content: '',
        replayUrl: 'https://app.openreplay.com',
        currentUrl: 'https://app.stagehub.com',
        pageName: 'Default'
      } as SendFeedbackCommand;
      modal.value?.toggleOpen();
    }

    const toggleClosed = () => {
      modal.value?.toggleClosed();
    }

    const toast = useToast();
    return {modal, toggleClosed, toast, feedback, invoke, v$: useVuelidate()}
  },
  data() {
    return {
      isLoading: false
    }
  },
  validations() {
    return {
      feedback: {
        content: {required}
      }
    }
  },
  mounted() {
    bus.on('modals:feedback:show', this.invoke)
  },
  methods: {
    sendFeedback() {
      this.v$.$touch()
      if (!this.v$.invalid && this.feedback.content !== '') {
        this.isLoading = true;
        if (process.env.VUE_APP_ANALYTICS === 'enabled') {
          this.feedback.replayUrl = `https://app.openreplay.com/${process.env.VUE_APP_OPENREPLAY_PROJECTID}/session/${openReplayTracker.getSessionID()}`
          openReplayTracker.issue('Feedback', {category: this.feedback.category})
          H.addSessionFeedback({
            verbatim: this.feedback.content
          })
        }

        this.feedback.currentUrl = `${document.location.protocol}//${document.location.host}${this.$route.fullPath}`
        this.feedback.pageName = this.$route.meta.pageName as string

        if (process.env.VUE_APP_ANALYTICS === 'enabled') {
          UserService.sendFeedback(this.feedback).then(() => {
            this.toggleClosed()
            this.toast.success("Feedback Sent!")
          }).catch(err => {
            let errorDetails = err.response.data as ProblemDetails
            this.toast.error(errorDetails.detail)
          }).finally(() => {
            this.isLoading = false;
            WebAnalytics.trackFlexible('Sent Feedback', {
              category: this.feedback.category,
              allowContact: this.feedback.contactMe
            });
          });
        }
      }
    }
  }
})
