
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import Button from "@/components/UI/Button.vue";
import {Invitation} from "@/models/user/Invitation";
import {MembershipRole} from "@/models/enums/Role";
import {MembershipStatus} from "@/models/enums/MembershipStatus";
import {UserService} from "@/services/UserService";
import {bus} from "@/modules/eventBus";
import {useUser} from "@/store/pinia/userStore";
import {isPlatform, toastController} from "@ionic/vue";
import {useToast} from "vue-toastification";
import {ProblemDetails} from "@/models/ProblemDetails";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  setup() {
    const modal = ref<InstanceType<typeof ModalBase>>();
    let invitation = ref<Invitation>({
      title: '',
      organizationId: '',
      role: MembershipRole.Member,
      productionId: '',
      status: MembershipStatus.PendingUser
    } as Invitation);
    const toggle = (_invitation: Invitation) => {
      invitation.value = _invitation
      modal.value?.toggle()
    }
    const toggleClosed = () => {
      modal.value?.toggleClosed()
    }
    const userStore = useUser();
    return {modal, toggle, invitation, toggleClosed, userStore, toast: useToast()}
  },
  components: {Button, ModalBase},
  data() {
    return {
      isLoading: false
    }
  },
  mounted() {
    bus.on('modals:invitations:view', invitation => {
      this.toggle(invitation as Invitation)
    })
  },
  methods: {
    submit: function (willAccept: boolean) {
      this.isLoading = true;
      UserService.respondToInvitation({
        doesAccept: willAccept,
        organizationId: this.invitation.organizationId,
        productionId: this.invitation.productionId
      }).then(() => {
        if (willAccept) {
          bus.emit('invitations:actions:accept')
          this.toggleClosed();
          this.navigateToEntity()
        } else {
          this.toggleClosed();
          if (isPlatform('capacitor')) {
            toastController.create({
              message: 'Invitation Declined',
              duration: 2000
            }).then(toast => {
              toast.present()
            });
          } else {
            this.toast.info('Invitation Declined')
          }
        }
      }).catch((err) => {
        let problemDetails = err.data as ProblemDetails
        if (isPlatform('capacitor')) {
          toastController.create({
            message: problemDetails.detail,
            duration: 2000
          }).then(toast => {
            toast.present()
          });
        } else {
          this.toast.info(problemDetails.detail)
        }
      }).finally(() => {
        this.toggleClosed()
        this.userStore.fetchInvitations()
        this.userStore.fetchProductionMemberships()
        this.userStore.fetchOrganizationMemberships()
        this.isLoading = false
        WebAnalytics.trackFlexible(willAccept ? 'Accepted Invitation' : 'Declined Invitation', {
          isProduction: this.invitation.productionId !== '',
          isOrganization: this.invitation.organizationId !== ''
        })
      })
    },
    navigateToEntity() {
      if ((this.$route.name as string).indexOf('onboard') === -1) {
        if (this.invitation.organizationId !== "00000000-0000-0000-0000-000000000000") {
          this.$router.push({name: 'organization-overview', params: {organizationId: this.invitation.organizationId}})
        } else {
          this.$router.push({name: 'production-overview', params: {productionId: this.invitation.productionId}})
        }
      }
    }
  }
})
