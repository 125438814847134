<template>
  <div class="page-title d-flex align-items-center flex-wrap me-3 mb-md-5 mb-lg-0">
    <h1 class="d-none d-lg-flex d-flex align-items-center text-dark fw-bolder fs-3 my-1">
      My Communications
    </h1>
    <span class="d-none d-md-flex h-20px border-gray-200 border-start mx-4"></span>
    <div class="d-none d-md-flex overflow-auto h-55px">
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
})
</script>
