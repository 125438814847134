
import {defineComponent, reactive, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import Button from "@/components/UI/Button.vue";
import InputGroup from "@/components/UI/InputGroup.vue";
import Selector2 from "@/components/inputs/SSelect2.vue";
import {CallType} from "@/models/scheduling/CallType";
import {CallTypeService} from "@/services/CallTypeService";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {bus} from "@/modules/eventBus";
import {useToast} from "vue-toastification";
import {WebAnalytics} from "@/modules/webAnalytics";
import ColorSelector from "@/components/UI/ColorSelector.vue";

export default defineComponent({
    setup(_, {emit}) {
        const modal = ref<InstanceType<typeof ModalBase>>();
        let callType = reactive<CallType>({callTypeId: '', color: 0, name: ''} as CallType);
        let mode = ref<string>('add');
        const invokeAdd = () => {
            callType = {callTypeId: '', color: 0, name: ''} as CallType
            mode.value = 'add'
            modal.value?.toggle()
        }
        const invokeUpdate = (_callType: CallType) => {
            //console.log('invokeUpdate', _callType);
            Object.assign(callType, _callType)
            mode.value = 'update'
            modal.value?.toggle()
        }
        const toggleClosed = () => {
            modal.value?.toggle();
        }
        const emitAdded = (_callType: CallType) => {
            emit('added', _callType)
        }
        return {
            modal,
            mode,
            invokeAdd,
            invokeUpdate,
            callType,
            toggleClosed,
            emitAdded,
            v$: useVuelidate(),
            toast: useToast()
        }
    },
    components: {ColorSelector, Selector2, InputGroup, Button, ModalBase},
    emits: ['added'],
    data() {
        return {
            colorOptions: [
                {
                    text: 'Default',
                    id: 0
                }, {
                    text: 'Red',
                    id: 1
                }, {
                    text: 'Rust',
                    id: 2
                }, {
                    text: 'Pink',
                    id: 3
                }, {
                    text: 'Dark Blue',
                    id: 4
                }, {
                    text: 'Light Blue',
                    id: 5
                }, {
                    text: 'Yellow',
                    id: 6
                }, {
                    text: 'Orange',
                    id: 7
                }, {
                    text: 'Gray',
                    id: 8
                }, {
                    text: 'Purple',
                    id: 9
                }, {
                    text: 'Green',
                    id: 10
                }, {
                    text: 'Light Pink',
                    id: 11
                }, {
                    text: 'Dark Blue',
                    id: 12
                }, {
                    text: 'Dark Purple',
                    id: 13
                }, {
                    text: 'Light Yellow',
                    id: 14
                }, {
                    text: 'Light Orange',
                    id: 15
                }, {
                    text: 'Azure',
                    id: 16
                }
            ],
            isLoading: false
        }
    },
    validations() {
        return {
            callType: {
                name: {required},
                color: {required}
            }
        }
    },
    mounted() {
        bus.on('modals:calltype:add', this.invokeAdd)
        bus.on('modals:calltype:update', callType => {
            this.invokeUpdate(callType as CallType)
        })
    },
    methods: {
        add() {
            this.v$.callType.$touch()
            if (!this.v$.callType.$invalid) {
                this.isLoading = true
                if (this.isOrganization) {
                    return;
                } else {
                    CallTypeService.create({
                        name: this.callType.name,
                        color: this.callType.color,
                        productionId: this.productionId
                    }).then(response => {
                        this.emitAdded(response.data)
                        bus.emit('modals:calltype:added', response.data)
                        this.toggleClosed()
                    }).catch(() => {
                        this.toast.error('An error occurred.')
                    }).finally(() => {
                        this.isLoading = false
                        WebAnalytics.trackFlexible('Added Call Type', {
                            productionId: this.productionId,
                            color: this.callType.color,
                        })
                    })
                }
            }
        },
        update() {
            this.v$.callType.$touch()
            if (!this.v$.callType.$invalid) {
                CallTypeService.update(this.callType.callTypeId, this.callType).then(() => {
                    Swal.fire('Call Type Updated')
                    bus.emit('modals:calltype:updated')
                    bus.emit('modal:scheduling:updated')
                    this.toggleClosed()
                    this.isLoading = false
                }).catch(() => {
                    this.toast.error('An error occurred.')
                }).finally(() => {
                    this.isLoading = false
                    WebAnalytics.trackFlexible('Updated Call Type', {
                        productionId: this.productionId,
                        color: this.callType.color,
                    })
                })
            }
        },
        remove() {
            Swal.fire({
                title: "Are you sure?",
                text: 'This call type.',
                confirmButtonText: 'Remove Call Type',
                showConfirmButton: true,
                showCancelButton: true,
                cancelButtonText: 'Keep Call Type'
            }).then(response => {
                if (response.isConfirmed) {
                    CallTypeService.remove(this.callType.callTypeId).then(() => {
                        bus.emit('modals:calltype:removed', this.callType.callTypeId)
                        this.toggleClosed()
                    }).catch(() => {
                        Swal.fire('One or more calls are scheduled using this call type. Please update those calls, and try again.')
                    }).finally(() => {
                        Swal.fire('Call Type Removed')
                        WebAnalytics.trackFlexible('Removed Call Type', {
                            productionId: this.productionId
                        })
                    })
                }
            })
        },
    },
    computed: {
        title(): string {
            return this.mode === 'add' ? 'Add New Call Type' : `Manage ${this.callType.name}`
        },
        isOrganization(): boolean {
            return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
        },
        organizationId(): string {
            return this.$route.params['organizationId'] as string
        },
        productionId(): string {
            return this.$route.params['productionId'] as string
        }
    }
})
